// Colors
$blue-100: #f6faff;
$blue-400: #5da9fe;
$blue-500: #02F1FF; // Primary Brand
$blue-600: #00AEEF; 

$black-500: #212B35;
$black-600: #192124;
$black-700: #182026;
$black-800:#21292D;
$black-900:#151B1D;
$black-999: #000000;

$red-100: #fbdbd8;
$red-400: #F44358;
$red-500: #ea4e3d;
$red-600:#C63C31;

$orange-100: #ffe5b4;
$orange-400: #ffa700;
$orange-500: #ffaa09;

$brown-500: #bc9f65;
$brown-300: #ddc186;

$green-100: #c7f1c7;
$green-400: #06DB04;
$green-500: #04be02;

$gray-50: #556365;
$gray-100: #707070;
$gray-200: #93A2A7;
$gray-400: #cbced8;
$gray-500: #a5aabc;
$gray-600: #6e717a;

$slate-gray-100: #f0f6ff;
$slate-gray-200: #212B35;  //hr r77
$slate-gray-300: #abb8d6;
$slate-gray-400: #8b9ccb;
$slate-gray-500: #C5C7C7; // Body/Content Text
$slate-gray-700: #444c64; // Header/Button/Select Text
$slate-gray-800: #C5C7C7; //font r77

$dark-gray-600: #212B35; //border color r77
$dark-gray-700: #192124; //body color r77
$dark-gray-800: #151B1D; //btn form color r77
$beach-300: #e0dddd; //r77

//Font-weight
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

//Font-size
$text-10: 0.63rem;
$text-12: 0.75rem;
$text-14: 0.875rem;
$text-16: 1rem;
$text-18: 1.125rem;
$text-20: 1.25rem;
$text-24: 1.5rem;
$text-30: 1.875rem;
$text-36: 2.25rem;
$text-48: 3rem;
$text-60: 3.75rem;
$text-72: 4.5rem;
$text-96: 6rem;
$text-128: 8rem;

//Line-height
$leading-tight: 1.25;
$leading-snug: 1.375;
$leading-normal: 1.5;
$leading-relaxed: 1.625;
$leading-loose: 2;

//Shadows
//Card Shadow
$slate-gray-card-shadow: 0 4px 12px 0 rgba($slate-gray-500, 0.25);
$slate-black-shadow: 0 4px 12px 0 rgba($black-999, 0.25);
//White Buttons/Objects
$slate-gray-shadow: 0 3px 5px rgba($slate-gray-500, 0.25);
//Blue Buttons/Objects
$blue-shadow: 0 3px 5px rgba($blue-500, 0.25);

//Hover Shadow
//White Hover Buttons/Objects
$slate-gray-hover-shadow: 0 0 0 0.25rem rgba($slate-gray-500, 0.25);
//Blue Hover Buttons/Objects
$blue-hover-shadow: 0 0 0 0.25rem rgba($blue-500, 0.25);
//Red Hover Buttons/Objects
$red-hover-shadow: 0 0 0 0.25rem rgba($red-500, 0.25);

@keyframes fadein-right {
  from {
    opacity: 0;
    transform: translateX(25%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeout-right {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(25%);
  }
}

@keyframes fadein-left {
  from {
    opacity: 0;
    transform: translateX(-25%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeout-left {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(-25%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zonIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #dec07e;
  }
}