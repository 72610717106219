.login-video {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: brightness(0.7);
  &.mobile {
    width: 100vw;
  }
}

.register-section {
  background-image: url("../../../images/register/auth-background.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-top: 4.68rem;
  padding: 3rem 0;
  .logo-image {
    max-width: 100%;
    width: 40%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  .x-container-no-m {
    display: flex;
    gap: 1rem;
    &.mobile {
      padding: unset;
    }
    .jackpot {
      background-color: $black-600;
      border-radius: .7rem;
      border: 1.5px solid $black-500;
      box-shadow: $slate-black-shadow;
      width: 100%;
      display: flex;
      justify-content: center; 
      height: auto;     
      img {
        width: 100%;
        height: auto;     
      }
    }
    .register-title {
      font-size: $text-18;
      font-weight: $font-semibold;
      color: $slate-gray-500 !important;
    }
    .register-form {
      background-color: $black-600;
      border-radius: .7rem;
      border: 1.5px solid $black-500;
      box-shadow: $slate-black-shadow;
      padding: 2rem;
      width: 550px;
      // min-height: 100vh;
      // justify-content: center;
      display: flex;
      flex-direction: column;
      .pi-sec-lvl-col {
        margin-top: 0.25rem;
        small {
          font-size: 0.767em;
          color: $red-500;
        }
      }
      .checklist {
        color: #fff;
        .form-check-input[type="checkbox"] {
          border-radius: 1rem;
          background-color: $black-900;
          &:checked {     
            background-image: url("../../../images/icons/mobile/checked-blue.webp");
            border: unset;
          }
        }
      }
      .register-recaptcha {
        // box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 0.3);
        border-radius: .3rem;
        background-color: $black-900;
        border: 1.5px solid $black-500;
        width: 100%;
        text-align: left;
        padding: 0.45rem;
        margin-top: 1rem;
        img {
          margin: 0rem 1rem 0rem 0.3rem !important;
          width: 1.5rem;
        }
      }
      .btn-blue {
        height: rem(40px);
        border-radius: .3rem;
        width: 100%;
        margin-top: 1rem;      
        border-color: $blue-500;
      }
      .checklist,
      .small-w {
        margin-top: .75rem;
        small {
          color: $slate-gray-500;
        }
      }
      p {
        color: #fff;
      }
      .contact-us-btn {
        img {
          width: 2rem;
          padding-right: 0.5rem;
        }
      }
      .bottom-content {
        small {
          display: block;
          .line {
            overflow: hidden;
            text-align: center;
            &:before {
              right: 0.5em;
              margin-left: -50%;
              background-color: $gray-600;
              content: "";
              display: inline-block;
              height: 1px;
              position: relative;
              vertical-align: middle;
              width: 25%;
            }
            &:after {
              background-color: $gray-600;
              content: "";
              display: inline-block;
              height: 1px;
              position: relative;
              vertical-align: middle;
              width: 25%;
              left: 0.5em;
              margin-right: -50%;
            }
          }
        }
        .google-tele-bg {
          margin-top: 1rem;
          button {
            background-color: rgba(255, 255, 255, 0.1);
            margin: 0.25rem;
            border: unset;
            border-radius: rem(10px);
            box-shadow: inset 0rem 0rem rem(10px) rem(3px) rgba(255, 255, 255, 0.3);
            width: rem(60px);
            height: rem(60px);
            &:hover {
              box-shadow: $blue-hover-shadow;
            }
          }
        }
      }
    }
    .input-group {      
      display: flex;
      margin-top: 1rem;   
      &.no-margin {
        margin: 0 !important;
      }
      .invalid-feedback {
        color: $red-500;
        line-height: 1;
        text-align: left;
        margin-top: 0.4rem;
      }
      &:focus-within {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &::before {
        content: "";
        height: rem(40px);
        background-color: $black-900;
        border-radius: .3rem;
        border: 1.5px solid $black-500;
        width: 100%;
        position: absolute;
      }
      &::after {
        content: "";
        // box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 0.3);
        height: rem(40px);
        border-radius: rem(50px);
        width: 100%;
        position: absolute;
        z-index: -1;
        background-color: rgba(255, 255, 255, 0.2);        
      }
      input:-webkit-autofill {
        -webkit-text-fill-color: #fff !important;
        transition: background-color 5000s ease-in-out 0s;
      }
      .left-icon {
        background-color: unset;
        border-radius: rem(50px) 0rem 0rem rem(50px);
        border: none;
        padding: 0.45rem !important;
        padding-left: 0.85rem !important;
        img {
          width: 1.5rem;
        }
      }      
      .input-group-text {
        border: none;
        padding: 0px;
        background: unset !important;
        .right-icon {
          background-color: unset;
          border-radius: 0rem rem(50px) rem(50px) 0rem;
          border: none;
          height: 100%;
          width: rem(50px);
          z-index: 99;
          img {
            width: 1.5rem;
          }
        }
      }
      .out-icon {
        position: absolute;
        left: 100%;
        padding: 0.5rem;
      }
      .form-control {
        background-color: unset;
        border: none;
        color: #fff;
        height: rem(40px);
        &::placeholder {
          color: $gray-50;
        }
        &:focus-within {
          border-color: inherit;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }
    }

    .recaptcha-modal {
      .modal-content {
        background-color: unset;
        border: unset;
      }
      .vertifyWrap {
        .sliderContainer {
          border-radius: rem(50px);
          background-color: rgba(255, 255, 255, 0.2);
          border: unset;
          box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 0.3);
          margin-top: 0px;
        }
        .sliderContainer_success,
        .sliderContainer_fail,
        .sliderContainer_active {
          line-height: rem(35px);
          &:hover {
            line-height: rem(35px);
          }
        }
        .sliderMask {
          border: unset !important;
          background: none !important;
          background-color: none !important;
        }
        .slider {
          border-radius: rem(50px);
          background-color: rgba(255, 255, 255, 0.2);
          border: unset;
          box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 0.3);
        }
        .loadingContainer {
          span {
            visibility: hidden;
            position: relative;
            &::after {
              visibility: visible;
              position: absolute;
              top: 0;
              left: 0;
              content: "Loading...";
            }
          }
        }
      }
    }
    .caution-text{
        color: $red-500;
        img{
        width: 0.8rem;
        margin-right: 0.2rem;
        margin-bottom: 0.1rem;
        }
    }

    //forget-password-part
    .forget-password-wrap {
      width: 585px;
      min-height: 100vh;
      justify-content: start;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      &.mobile {
        width: 100%;
        min-height: unset;
        .forget-password-content {
          padding: 1rem 1.5rem 2.5rem;
          min-height: unset;
          // background-color: rgba(black, 0.5);
          box-shadow: unset;
          border: unset;
          border-radius: unset;
          .password-form {
            width: 90%;
            margin: auto;
            margin-bottom: 1rem;
          }
          .forgot_password_form {
            padding: 1.25rem 0;
            margin: auto;
            width: 90%;
          }
          .forget-pwd-btn {
            display: flex;
            align-items: center;
            button {
              height: 5rem;
              border-bottom: unset;
              width: 8rem;
              p {
                margin-top: .5rem;
                color: $slate-gray-500;
              }
            }
          }
        }
        .count {
          position: unset;
          float: right;
          margin-top: 0.2rem;
          top: unset;
          margin-left: unset;
        }
        .right-btn-bg {
          .right-btn {
            padding: 0.3rem 1rem;
          }
        }
      }
      h4 {
        color: $slate-gray-500;
        font-weight: 600;
        // margin-top: 1.5rem;
      }
      p {
        color: $gray-50;
      }
      .forget-password-content {
        background-color: $black-600;        
        border: 1.5px solid $black-500;
        box-shadow: $slate-black-shadow;
        padding: 2rem;
        border-radius: .7rem;
        min-height: 100vh;
        // box-shadow: inset 0px 0px 10px 5px rgba(255, 255, 255, 0.3);
        // margin-bottom: 1rem;
        .password-form {
          width: 100%;
          height: auto;
          // padding-top: 1.5rem;      
          .col-form-label {
            color: $slate-gray-500;
            margin-top: 1.5rem;
            &.margin-top {
              margin-top: 4rem;
            }
          }              
        }
        .forget-pwd-btn {
          margin-top: 1rem;
          display: flex;
          justify-content: center;          
          button {
            background-color: unset;
            color: $gray-50;
            font-weight: 500;
            border: unset;
            border-bottom: 2px solid $gray-50;
            height: 50px;
            width: 100%;
            // margin: 0px 1rem;
            // img {
            //   width: 100%;
            //   height: 100%;
            // } 
            &.active {
              color: $blue-500;              
              border-bottom: 2px solid $blue-500;
              width: 100%;
            }           
          }          
        }
      }
      small {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        color: $gray-200;
        cursor: pointer;
        &:before {
          font-size: $text-30;
          content: "<";
        }
      }
      .left-text {
        text-align: center;
        color: $gray-50;
        padding-left: 0.8rem !important;
      }
      .btn-blue {
        height: rem(40px);
        border-radius: .5rem;
        width: 100%;
        margin-top: 2.5rem;
        &.desktop{
          width: 100%;
        }
        &.mobile {
          height: 3rem;
          width: 90%;
          margin-left: 1.2rem;
          margin-top: 1.5rem;
        }
      }
      .count {
        position: absolute;
        color: $blue-400;
        margin-left: 11.4rem;
        top: 25%;
      }
      .right-btn-bg {
        padding: 0rem 0.5rem;
        // border-radius: rem(50px);
        background: unset !important;
      }
      .right-btn {
        background-color: $blue-500;
        border-radius: .3rem;
        padding: 0.28rem 1rem;
        color: $black-900;
        border: unset;
      }
    }

    //validated-form
    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background-image: url("../../../images/register/icon-caution.webp");
      background-repeat: no-repeat;
      margin-right: -35px;
      padding-right: 50px;
      background-position: right calc(0.375em + 0.1875rem) center !important;
      &.no-border {
        margin-right: -84px;
        padding-right: 85px;
      }
      &:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &.forget-pwd-desktop {
        margin-right: -137px;
        padding-right: 147px;
      }
    }

    .was-validated .form-control:valid,
    .form-control.is-valid {
      background-image: url("../../../images/register/icon-checked.webp");
      background-repeat: no-repeat;
      margin-right: -35px;
      background-size: 18px;
      padding-right: 50px;
      background-position: right calc(0.375em + 0.1875rem) center !important;
      &.no-border {
        margin-right: -84px;
        padding-right: 85px;
      }
      &:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &.forget-pwd-desktop {
        margin-right: -137px;
        padding-right: 147px;
      }
    }
  }

  //for mobile top
  &.register-m-section {    
    padding-top: unset;
    margin-top: 0;
    background-image: unset;
    background-color: $black-900;
    padding-bottom: 1rem;
    img {
      &.jackpot-m {
        width: 100%;
      }
    }
    .forget-password-header {     
      background-color: $black-600;
      padding: 2rem 1rem 1rem 1rem;
      margin-bottom: 1.5rem;
      h4 {
        font-weight: unset;
        color: $slate-gray-500;   
        font-size: $text-16; 
        text-align: center;    
      }
      a, small {
        position: absolute;
        top: 3%;
        left: 3%;
        svg {
          font-size: $text-36;
          color: $slate-gray-500;
        }
      }
    }
    .x-container-no-m {
      min-width: unset;
      .register-form {
        background-color: unset;
        border: none;
        box-shadow: unset;
        padding: unset;
        width: 25rem;
        margin: auto;
        min-height: unset;
        p {
          color: $gray-50;
        }
        .nav-tabs {
          border-bottom: 0;
          margin-bottom: rem(1px);
          margin-top: 1rem;
          .nav-item {
            width: 50%;
          }
          .nav-link {
            text-align: center;
            padding: 1rem;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            background-color: $black-900;
            color: $slate-gray-800;
            border: .1rem solid $black-800;
            margin-bottom: rem(-1px);
            &.active {
              background-color: $black-600;
              color: $blue-500;
              border: .1rem solid $black-800;
            }
          }
        }
        .member-form {
          padding: 0.5rem 2rem 2.5em 2rem;
          background-color: $black-600;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
          box-shadow: $slate-black-shadow;
          border: .1rem solid $black-800;
          border-top: unset;
          &.social{
            border-radius: 1rem;
            margin-top: 1rem;
          }
        }
        .bottom-content {
          .google-tele-bg {
            button {
              margin: 0.5rem;
              img {
                width: 2rem;
                height: 2rem;
              }
            }
          }
        }
        .register-recaptcha {
          // box-shadow: inset 0rem 0rem rem(10px) rem(5px)
          //   rgba(255, 255, 255, 0.3);
          //   background-color: rgba(white, 0.15);
          img {
            height: 1.5rem;
            width: 1.5rem;
            margin: 0rem 0.5rem 0rem 0rem;
          }
          span {
            color: $gray-50 !important;
          }
        }
        .btn-blue {
          height: 3rem;
          margin-top: 2rem;
        }
      }
      .input-group {
        &::after {
          background-color: rgba(white, 0.3);
          box-shadow: inset 0rem 0rem rem(10px) rem(5px)
            rgba(255, 255, 255, 0.8);
        }
      }
      .recaptcha-modal {
        .modal-dialog {
          max-width: unset;
          .sliderContainer {
            height: rem(40px);
            background-color: rgba(255, 255, 255, 0.3);
            box-shadow: inset 0rem 0rem rem(10px) rem(5px)
              rgba(255, 255, 255, 0.5);
            .sliderMask {
              line-height: rem(38px);
              .slider {
                width: rem(40px);
                height: rem(40px);
                background-color: rgba(255, 255, 255, 0.2);
                box-shadow: inset 0rem 0rem rem(10px) rem(5px)
                  rgba(255, 255, 255, 0.3);
                  .sliderIcon{
                    font-size: 1rem;
                  }
              }
            }
          }
          .loadingIcon,
          .refreshIcon {
            width: 2rem;
            height: 2rem;
            background-size: 2rem;
          }
        }
      }
      .was-validated .form-control:invalid,
      .form-control.is-invalid {
        background-size: 1.3rem;
        margin-right: rem(-33px);
        padding-right: rem(50px);
        &.no-border {
          margin-right: rem(-83px);
          padding-right: rem(80px);
        }
        &.forget-pwd-mobile {
          margin-right: rem(-290px);
          padding-right: rem(290px);
        }
      }
      .was-validated .form-control:valid,
      .form-control.is-valid {
        background-size: 1.5rem;
        margin-right: rem(-35px);
        padding-right: rem(50px);
        &.no-border {
          margin-right: rem(-84px);
          padding-right: rem(80px);
        }
        &.forget-pwd-mobile {
          margin-right: rem(-293px);
          padding-right: rem(290px);
        }
      }
    }
  }
}

//use with forget password page select
.reg-and-log-select {
  .react-select__control {
    width: rem(115px);
    // border: 0 !important;
    border-right: 0 !important;
    // border-radius: rem(50px) 0rem 0rem rem(50px) !important;
    height: rem(40px);
    background-color: unset !important;
    &:focus-within {
      border: none !important;
      box-shadow: unset !important;
    }
    .react-select__single-value {
      p:nth-child(n + 3) {
        margin-left: rem(14px);
      }
    }
  }

  .react-select__menu {
    width: 350px;
    .react-select__option {
      p {
        color: $slate-gray-500 !important;
        margin-right: rem(14px);
      }
      img {
        width: 35px;
        height: 35px;
      }
    }
  }
  &.mobile {
    .react-select__menu {
      width: rem(250px);
      .react-select__option {
        height: 3rem;
        padding-left: 1rem;
        img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
    .react-select__single-value {
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
