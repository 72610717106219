.msg-tabs-col {
  margin-bottom: -1.25rem;

  .nav-tabs {
    border-bottom: 0;

    .nav-item {
      .nav-link {
        position: relative;
        color: $slate-gray-800;
        background: transparent;
        font-weight: $font-semibold;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: rem(3px) solid transparent;
        @include no-focus-hover($slate-gray-300);

        &.active {
          color: $blue-500;
          border-bottom: rem(3px) solid $blue-500;
        }
      }
    }
  }
}

.msg-tabs-content {
  height: inherit;

  .tab-content {
    height: inherit;

    .tab-pane {
      height: inherit;
    }
  }
}

.msg-list-col {
  display: flex;
  flex-direction: column;
  height: 100%;

  .msg-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 0.5rem;

    .msg-list-group {
      background-color: $dark-gray-700;
      padding: 0;
      border: 0;
      box-shadow: none;
      .list-group-item {
        .form-check-input {
          background-color: $dark-gray-700;
          border-radius: 99px;
          &:checked {     
            background-image: url("../../../../images/icons/mobile/checked-blue.webp");
            border: unset;
          }
        }
      }
    }

    table>tbody>tr:first-child {
      .list-group-item {
        border-top: 0;
      }
    }
    .list-group-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // max-width: 850px;
      padding: 0.75rem;
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      border-color: $slate-gray-200;
      border-top: rem(2px) solid $dark-gray-600;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: $dark-gray-600;
      }

      .msg-icon-col {
        position: relative;

        img {
          width: 2rem;
          height: 2rem;
        }

        .notify-red-dot {
          right: -20%;
        }
      }

      h6>span {
        white-space: nowrap;
      }

      h6>span,
      p,
      span {
        font-size: $text-12;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h6 {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        width: 30%;
        line-height: 1;
      }

      p {
        color: $slate-gray-500;
        width: 50%;
      }

      &>span:not(h6) {
        color: $slate-gray-300;
      }
    }
  }
}

.msg-edit-col {
  .msg-edit-panel {
    display: inline-flex;

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }

    &>* {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    .form-check {
      align-self: center;
      margin-bottom: 0;
      // &.mobile {
      //   margin-right: none;
      //   min-height: unset;
      //   padding-left: unset;
      //   margin-bottom: unset;
      //   padding-top: 0.2rem;
      // }
    }

    .btn.text-icon {
      font-size: $text-12;
      font-weight: $font-regular;

      &:disabled,
      &[disabled] {
        color: $slate-gray-300;
      }

      img,
      svg {
        width: 20px;
        height: auto;
      }
    }
  }
}

.msg-inner-col {
  .msg-inner-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray-50;

    .msg-inner-title {
      width: 80%;

      h5 {
        color: $slate-gray-800;
        margin-bottom: 0;
      }

      p {
        margin-top: 0.5rem;
        color: $gray-50;
      }
    }

    button {
      display: inline-block;
    }
  }

  .msg-inner-content {
    margin: 1rem 0;

    p {
      line-height: $leading-loose;
    }
  }
}

.msg-inner-col.feedback {
  .msg-inner-header {
    align-items: center;

    .msg-inner-title {
      display: flex;
      align-items: center;

      img {
        width: 35px;
        height: 35px;
        margin-right: 1.25rem;
      }
    }
  }

  .msg-inner-content {
    margin: 0;

    .list-group {
      .list-group-item {
        display: flex;
        justify-content: space-between;
        max-width: 850px;
        padding: 0.75rem 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        border-color: $slate-gray-200;

        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          object-fit: cover;
          margin-top: 7px;
          margin-right: 1.25rem;
        }

        p {
          color: $slate-gray-500;
          margin-bottom: 1rem !important;
        }

        span {
          color: $slate-gray-300;
        }
      }
    }
  }
}

.checkbox-m-message {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-left: 0.5rem;

  .message-m-content {
    display: flex;
    justify-content: space-between;
  }

  p {
    padding-left: 0.5rem;
    width: 90%;
  }
}


.pf-m-dashboard {
  .msg-tabs-col {
    margin-bottom: unset;
    background-color: $dark-gray-700;
    padding: 0 1rem;

    .nav-tabs {
      justify-content: space-between;

      .nav-item {
        flex: 1;

        .nav-link {
          margin: 0 auto;
          font-weight: $font-regular;

          &:not(.active) {
            color: $slate-gray-300;
          }
        }
      }
    }
  }

  .msg-list-col {
    .msg-list {
      overflow: hidden;

      .react-bootstrap-table {
        overflow: hidden;

        table>tbody>tr:first-child {
          .list-group-item {
            border-top: 0;
          }
        }
      }

      .msg-list-group {
        min-width: unset;

        .list-group-item {
          position: relative;
          left: -2.5rem;
          width: 29rem;
          padding: 1rem;
          transition: all .3s ease-in-out;

          &.show {
            left: 0;

            .form-check-input {
              opacity: 1;
              border-radius: 99px;
              background-color: $dark-gray-800;
              width: 1.2rem;
              height: 1.2rem;
              &:checked {                     
                background-image: url("../../../../images/icons/mobile/checked-blue.webp");
                border: unset;
              }
            }
          }

          .form-check-input {
            opacity: 0;
          }

          .form-check-input,
          .msg-icon-col {
            margin: 0 auto;
          }

          .list-group-item-r {
            display: grid;
            gap: 0.25rem;
            width: 80%;

            label {
              display: flex;
              justify-content: space-between;

              h6 {
                width: rem(200px);
                margin-left: -0.25rem;
                color: $slate-gray-500;

                span {
                  font-size: $text-14;
                }
              }
            }

            p {
              width: 100%;
              text-align: left;
              color: $gray-200;
            }
          }
        }
      }
    }
  }

  .msg-edit-col {
    justify-content: space-between;
    position: fixed;
    height: 5rem;
    width: 100%;
    z-index: 999;
    bottom: 0;
    background-color: $dark-gray-700;
    padding: 1rem;
    bottom: -5rem;
    transition: all .3s ease-in-out;

    &.show {
      bottom: 0;
    }

    .btn.text-icon {
      font-size: $text-14;
    }
  }

  .msg-inner-col {
    padding: 1rem;

    .msg-inner-header {
      display: flex;
      justify-content: unset;
    }

    .msg-icon-col {
      margin-right: 0.75rem;

      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}