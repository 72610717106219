footer {
  padding: 1.5rem 0;
  background-color: $black-800;
  .footer-one {
    display: flex;
    justify-content: start;
    margin-top: 2.5rem;
    gap: 5rem;
    span {
      text-align: center;
      // padding: 0 1rem;
      p {
        color: $slate-gray-500;
        line-height: $leading-tight;
      }
      img,
      svg {
        width: auto;
        height: 35px;
        margin: 0 1.5rem 0 0;
      }
    }
  }
  hr {
    margin: 2rem 0 0 0;
    background-color: $gray-50;
  }
  .footer-two, .footer-three {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    margin-top: 2rem;
    & * {
      // justify-content: center;
      // margin: 0 auto;
    }
    p {
      // text-align: center;
      color: $slate-gray-500;
      max-width: 991px;
      font-size: $text-12;
    }
    .btn-social {
      display: flex;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        text-decoration: none;
        // border: 1.5px solid $slate-gray-100;
        // border-radius: 50%;
        color: $slate-gray-500;
        height: 35px;
        width: 30px;
        transition: all 0.3s ease-in-out;
        svg,
        img {
          width: 25px;
          height: 25px;
        }
        &:hover {
          color: $blue-500;
          // border-color: white;
          // background: white;
        }
      }
      span {
        margin-left: .5rem;
        width: 0.8px;
        height: 35px;
        background-color: $gray-50;        
      }
    }
    .navbar-nav {
      flex-direction: row;
      align-items: center;
      gap: 0.8rem;
      .nav-item {
        margin: 0 10px;     
        &:first-child {
          margin: 0 10px 0 0;
        }   
        .nav-link {
          color: $slate-gray-500;
          padding: unset;
          &:hover {
            color: $slate-gray-300;
          }
        }
      }
      span {
        width: 2px;
        height: 2px;
        background-color: $slate-gray-500;
        border-radius: 99px;
      }
    }
    small {
      color: $slate-gray-500;
    }
  }

  .modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding-top: 0;
          display: block;
        }
      }
    }
  }

  .event-bottom-deco{
    width: 100%;
    height: 40vh;
    position: absolute;
    top: -40vh;
    left: 0;
    object-fit: cover;
    object-position: 100% 20%;
    z-index: -1;
  }
  
}
