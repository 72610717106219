.bonus-col,
.bonus-m-col {
  .bonus-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid $slate-gray-200;
    border-radius: 0.5rem;
  }
  .bonus-content,
  .bonus-m-content {
    display: flex;
    align-items: center;
    img,
    svg {
      height: rem(50px);
      width: rem(50px);
      margin-right: rem(7px);
    }
    p {
      color: $slate-gray-800;
      font-weight: $font-medium;
      span {
        font-size: $text-20;
        word-break: break-all;
      }
    }
  }
}

.bonus-m-content {
  padding: 1rem;
  justify-content: center;
  background-color: white;
  box-shadow: $slate-gray-card-shadow;

}
#bonusTab {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  padding: 0rem 1rem;
  background-color: $dark-gray-700;
  li {
    // width: 100%;
    .nav-link {
      color: $slate-gray-700;
      min-height: 2.5rem;
      // width: 100%;
      text-align: center;
      padding: 0rem 0.5rem;
      &.active {
        color: $blue-500;
        border-bottom: rem(1px) solid $blue-500;
        // background-size: 100% 100%;
        // background-position: center;
        // background-image: url("../../../../images/bonus/active-btn.webp");
      }
    }
  }
}
.bonus-event-m-container {
  padding: 1rem 1rem 6rem 1rem;

  #bonusTabContent {
    .bonus-event-m-card {
      position: relative;
      border-radius: 0.75rem;
      overflow: hidden;
      // box-shadow: $slate-gray-card-shadow;
      background-color: $dark-gray-700;
      border:rem(1px) solid $dark-gray-600;
      &:not(:last-child) {
        margin-bottom: .75rem;
      }
      &:not(.w-border) .bonus-event-m-content {
        width: 77%;
        padding-right: 0.75rem;
        position: absolute;
        bottom: 0.75rem;
        right: 0;
        top:50%;
        transform: translateY(-50%);
      }
      &.w-border {
        &.w-bg {
          background-color: $dark-gray-700;
          border:rem(1px) solid $dark-gray-600;
         p{
          color: $gray-50!important;
         }
        }
        .bonus-event-m-content {
          padding: 0.75rem;
          img {
            width: 2.25rem;
            height: 2.25rem;
            object-fit: contain;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .bonus-event-m-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bonus-m-inside-container{
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        p {
          // width: 5rem;
          color: $slate-gray-800;
          font-weight: $font-medium;
          // font-size: $text-10;
          line-height: $leading-normal;
          span {
            color: $blue-600;
          }
        }
        .btn-gradient-blue {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2rem;
          width: 5rem;
          min-width: unset;
          white-space: normal;
          font-size: $text-10;
          &[disabled] {
            pointer-events: none;
            opacity: 0.75;
          }
        }
      }
      &.new-events{
        &::after {
          content: "";
          position: absolute;
          background: url("../../../../images/profile/icons/new-icon.svg");
          background-repeat: no-repeat;
          background-size: contain;
          top: rem(-0.25px);
          left: rem(-0.25px);
          width: 2rem;
          height: 2rem;
          // z-index: 1;
        }
      }
      &.new-events-id{
        &::after {
          content: "";
          position: absolute;
          background: url("../../../../images/profile/icons/new-icon-id.gif");
          background-repeat: no-repeat;
          background-size: contain;
          top: rem(-0.25px);
          left: rem(-1px);
          width: 2.5rem;
          height: 2.5rem;
          // z-index: 1;
        }
      }
    }
  }
}

.bonus-event-container {
  .bonus-event-card {
    position: relative;
    border-radius: 0.75rem;
    overflow: hidden;
    &:not(.w-border) {
      img {
        width: 100%;
        height: rem(130px);
        object-fit: cover;
      }
    }
    &.new-events{
      &::after {
        content: "";
        position: absolute;
        background: url("../../../../images/profile/icons/new-icon.svg");
        background-repeat: no-repeat;
        background-size: contain;
        top: rem(-0.25px);
        left: rem(-1px);
        width: 2.5rem;
        height: 2.5rem;
        // z-index: 1;
      }
    }
    &.new-events-id{
      &::after {
        content: "";
        position: absolute;
        background: url("../../../../images/profile/icons/new-icon-id.gif");
        background-repeat: no-repeat;
        background-size: contain;
        top: rem(-0.25px);
        left: rem(-1px);
        width: 2.5rem;
        height: 2.5rem;
        // z-index: 1;
      }
    }
    .bonus-event-content {
      position: absolute;
      text-align: center;
      padding: 0 1rem;
      width: 100%;
      bottom: 0.75rem;
    }
    p {
      font-size: $text-12;
      color: $slate-gray-500;
      margin-bottom: 0.25rem !important;
      span {
        color: $blue-600;
      }
    }
    .btn-outline-blue {
      font-size: $text-12;
      margin: 0 auto;
      border-radius: 1rem;
      height: unset;
      width: max-content;
      min-width: unset;
      background-color: white;
      &[disabled] {
        pointer-events: none;
        opacity: 0.75;
      }
    }
    &.w-border {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $dark-gray-800;
      border: rem(2px) solid $dark-gray-600;
      padding: 0 1rem;
      height: rem(130px);
      &.w-bg {
        background: $dark-gray-800;
        border: rem(2px) solid $dark-gray-600;
      }
      img {
        width: 3rem;
        height: 3rem;
        margin-bottom: 0.25rem;
      }
    }
  }
}

.bonus-btn-col {
  position: absolute;
  bottom: 0;
  padding: 1rem;
  background-color: white;
  box-shadow: $slate-gray-card-shadow;
  width: 100%;
  .btn-gradient-blue {
    width: 100%;
  }
}

