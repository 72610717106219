// Fonts
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


// Font Awesome
// $fa-font-path: "../../../node_modules/font-awesome/fonts" !default;
// @import 'node_modules/font-awesome/scss/font-awesome.scss';

@font-face {
  font-family: 'AdihausDIN';
  src: url('../fonts/AdihausDIN-Regular.eot');
  src: url('../fonts/AdihausDIN-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/AdihausDIN-Regular.woff2') format('woff2'),
      url('../fonts/AdihausDIN-Regular.woff') format('woff'),
      url('../fonts/AdihausDIN-Regular.ttf') format('truetype'),
      url('../fonts/AdihausDIN-Regular.svg#AdihausDIN-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+30-39;
}

@font-face {
  font-family: 'AdihausDIN';
  src: url('../fonts/AdihausDIN-Medium.eot');
  src: url('../fonts/AdihausDIN-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/AdihausDIN-Medium.woff2') format('woff2'),
      url('../fonts/AdihausDIN-Medium.woff') format('woff'),
      url('../fonts/AdihausDIN-Medium.ttf') format('truetype'),
      url('../fonts/AdihausDIN-Medium.svg#AdihausDIN-Medium') format('svg');
  font-weight: medium;
  font-style: normal;
  font-display: swap;
  unicode-range: U+30-39;
}

@font-face {
  font-family: 'AdihausDIN';
  src: url('../fonts/AdihausDIN-Bold.eot');
  src: url('../fonts/AdihausDIN-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/AdihausDIN-Bold.woff2') format('woff2'),
      url('../fonts/AdihausDIN-Bold.woff') format('woff'),
      url('../fonts/AdihausDIN-Bold.ttf') format('truetype'),
      url('../fonts/AdihausDIN-Bold.svg#AdihausDIN-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+30-39;
}

@font-face {
  font-family: 'AdihausDIN Cn';
  src: url('../fonts/AdihausDIN-Cn.eot');
  src: url('../fonts/AdihausDIN-Cn.eot?#iefix') format('embedded-opentype'),
      url('../fonts/AdihausDIN-Cn.woff2') format('woff2'),
      url('../fonts/AdihausDIN-Cn.woff') format('woff'),
      url('../fonts/AdihausDIN-Cn.ttf') format('truetype'),
      url('../fonts/AdihausDIN-Cn.svg#AdihausDIN-Cn') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+30-39;
}

@font-face {
  font-family: 'MicrosoftYaHeiUI';
  src: url('../fonts/MicrosoftYaHeiUILight.eot');
  src: url('../fonts/MicrosoftYaHeiUILight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MicrosoftYaHeiUILight.woff2') format('woff2'),
      url('../fonts/MicrosoftYaHeiUILight.woff') format('woff'),
      url('../fonts/MicrosoftYaHeiUILight.ttf') format('truetype'),
      url('../fonts/MicrosoftYaHeiUILight.svg#MicrosoftYaHeiUILight') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MicrosoftYaHeiUI';
  src: url('../fonts/MicrosoftYaHeiUIBold.eot');
  src: url('../fonts/MicrosoftYaHeiUIBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MicrosoftYaHeiUIBold.woff2') format('woff2'),
      url('../fonts/MicrosoftYaHeiUIBold.woff') format('woff'),
      url('../fonts/MicrosoftYaHeiUIBold.ttf') format('truetype'),
      url('../fonts/MicrosoftYaHeiUIBold.svg#MicrosoftYaHei') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MicrosoftYaHeiUI';
  src: url('../fonts/MicrosoftYaHeiUI.eot');
  src: url('../fonts/MicrosoftYaHeiUI.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MicrosoftYaHeiUI.woff2') format('woff2'),
      url('../fonts/MicrosoftYaHeiUI.woff') format('woff'),
      url('../fonts/MicrosoftYaHeiUI.ttf') format('truetype'),
      url('../fonts/MicrosoftYaHeiUI.svg#MicrosoftYaHei') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Microsoft YaHei Lobby UI';
  src: url('../fonts/MicrosoftYaHeiLobbyUI.eot');
  src: url('../fonts/MicrosoftYaHeiLobbyUI.eot?#iefix') format('embedded-opentype'),
      url('../fonts/MicrosoftYaHeiLobbyUI.woff2') format('woff2'),
      url('../fonts/MicrosoftYaHeiLobbyUI.woff') format('woff'),
      url('../fonts/MicrosoftYaHeiLobbyUI.ttf') format('truetype'),
      url('../fonts/MicrosoftYaHeiLobbyUI.svg#MicrosoftYaHeiLobbyUI') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



