.promos-container {
  .row {
    .col:first-child {
      width: 100%;
      max-width: 250px;
      height: auto;
      position: relative;
    }
  }

  .sideNav-bg {
    width: 100%;
    height: 590px;
  }

  .promos-sideNav-container {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    list-style-type: none;
    padding: 1rem;
    display: grid;
    height: 590px;
    align-content: center;

    li {
      display: flex;
      justify-content: center;
      cursor: pointer;

      .promos-sideNav {
        display: flex;
        padding: 0.5rem;
        align-items: center;
        background-image: url("../../../images/game-white.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 65px;
        width: 100%;
        transition: all 0.3s ease-in-out;

        span {
          width: 75px;
          padding: 1rem;

          .promos-icon {
            width: 100%;
            max-width: 45px;
            height: auto;
            // filter: grayscale(1);
            transition: all 0.3s ease-in-out;
            display: block;
          }
          .promos-icon-active{
            width: 100%;
            max-width: 45px;
            height: auto;
            display: none;
            transition: all 0.3s ease-in-out;
          }
        }

        p {
          transition: all 0.3s ease-in-out;
          font-weight: $font-semibold;
        }
      }

      &:first-child {
        .promos-icon {
          max-width: 30px !important;
        }
      }

      &:hover,
      &.active {
        .promos-sideNav {
          background-image: url("../../../images/game-blue.png");

          .promos-icon {
            display: none;
          }
          .promos-icon-active{
            display: block;
          }

          p {
            color: $slate-gray-100;
          }
        }
      }
    }
  }

  .promos-list {
    position: relative;

    .card-shadow {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      border-radius: 1rem;
      border: 2px solid $dark-gray-600;

      &:hover {
        box-shadow: $blue-shadow;
      }

      .lazy-load-image-background.blur.lazy-load-image-loaded {
        height: auto;
        width: 100%;
      }
    }

    .promos-title {
      position: absolute;
      top: 0%;
      left: 5%;
      // box-shadow: $slate-gray-shadow;
      max-width: 20%;

    }
  }
}

//promos-detail scss
.promos-table-section {
  display: flex;
  justify-content: center;

  .card-shadow {
    border-radius: 0.75rem;
  }

  .promos-table-container {
    width: 100%;
    padding: 2rem;
    background-color: $dark-gray-700;
    border: rem(2px) solid $dark-gray-600;

    h2 {
      color: $slate-gray-800;
      font-weight: $font-semibold;
      // text-shadow: rgba($blue-500, 0.2) 0 3px 6px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 32px;

      @include max-lg {
        font-size: $text-20;
        margin-bottom: 15px;
      }
    }

    .card-shadow {
      border-radius: 0.35rem;
    }

    .promos-claim-container {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      img {
        max-width: rem(25px);
        margin-right: 0.25rem;
      }
    }
  }
}

.promo-share {
  position: relative;
  z-index: 1;
  margin-top: -4rem;
  display: flex;
  margin-left: auto;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: flex-end;

  svg {
    font-size: 1rem;
  }

  @include max-lg {
    width: 18rem;
  }

  .btn-gradient-blue {
    border-radius: .5rem;
    border: 2px solid $blue-500;
  }
}

.promos-table-container,
.term-section {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: rem(2px) solid $dark-gray-600;
    border-radius: 0.75rem;
    overflow: hidden;

    tr {
      &:first-child {
        td {
          font-weight: $font-medium;
          color: $slate-gray-800;
          // background-color: $slate-gray-100;
          line-height: $leading-tight;
        }
      }

      &:nth-child(n + 1):not(:last-child) td {
        border-bottom: rem(1px) solid $slate-gray-200;
      }

      td {
        &:nth-child(n + 1):not(:last-child) {
          border-right: rem(1px) solid $slate-gray-200;
        }

        padding: 0.75rem;
        text-align: center;
      }
    }
  }

  // &.mobile {
  //   table {
  //     overflow: auto;
  //     display: block;

  //     tr {
  //       width: 100%;
  //     }
  //   }
  // }
}

  .mobile-view {
    .promos-table-section {
      .promos-table-container {
        padding: 1rem;
      }
    }
  }

  //term and condition listing scss
  .term-section {
    .card-shadow {
      border-radius: 0.75rem;
    }

    .term-container {
      padding: 2rem;
      background-color: $dark-gray-700;
      border: rem(2px) solid $dark-gray-600;

      img {
        width: 100%;
        height: auto;
      }

      .term-content {
        // background-image: url("../../../images/tnc/term-content.png");
        // background-size: 100% 100%;
        // background-repeat: repeat;
        // padding: 1rem;

        ol {
          list-style: none;
          counter-reset: my-awesome-counter;
          margin-bottom: 0;
        }

        li {
          white-space: pre-wrap;
          counter-increment: my-awesome-counter;
          padding-bottom: 1rem;
          position: relative;
          font-size: $text-16;

          // letter-spacing: rem(1px);
          &::before {
            content: counter(my-awesome-counter);
            background: unset;
            width: rem(25px);
            height: rem(25px);
            color: $blue-500;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: rem(-35px);
            border: rem(2px) solid $blue-500;
            border-radius: 100%;
          }
        }

        &.policy-content {
          padding: 2rem;

          h4 {
            margin-top: 2rem;
            font-weight: $font-semibold;
            color: black;
          }

          p {
            padding-bottom: 0.5rem;
          }

          ol {
            list-style: decimal;
            counter-reset: unset;
            padding-left: 1rem;
          }

          li {
            counter-increment: unset;

            &::before {
              content: unset;
            }
          }

          &.mobile {
            padding: 0rem 1rem 0rem 1rem;

            h6 {
              margin-top: 1rem;
              font-weight: $font-semibold;
              color: black;
            }
          }
        }
      }
    }
  }

  .mobile-view {
    .term-section {
      .term-container {
        padding: 1rem;

        .term-content {

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            span {
              font-size: unset !important;
            }
          }

          ol {
            &::before {
              width: 1.25rem;
              height: 1.25rem;
            }

            li {
              font-size: $text-14;
            }
          }
        }
      }
    }
  }

  //promos mobile scss
  .promos-mobile-section {
    .promos-m-header {
      width: 100%;
      height: 6.5rem;
      display: flex;
      flex-direction: column;
      color: $slate-gray-800;
      background-color: $dark-gray-700;
      // box-shadow: $slate-gray-card-shadow;

      h4 {
        height: rem(60px);
        padding-top: rem(40px);
        margin-bottom: 0;
        text-align: center;
      }

      .navbar-nav {
        flex-direction: row;
        width: 100%;
        overflow: auto;
        margin-top: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        .nav-item {
          margin: auto;
          padding: 0 0.5rem;
          white-space: nowrap;

          .nav-link {
            display: block;
            position: relative;
            color: $slate-gray-500;
            overflow: hidden;

            &.icon {
              display: grid;
              text-align: center;

              img {
                width: rem(22px);
                height: rem(22px);
                margin: 0 auto;
              }
            }

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: rem(3px);
              background-color: $blue-500;
              opacity: 0;
              transition: opacity 300ms, transform 300ms;
              transform: translate3d(-100%, 0, 0);
            }

            &:hover::after,
            &:focus::after {
              opacity: 1;
              transform: translate3d(0, 0, 0);
            }

            &.active {
              color: $blue-500;

              &::after {
                opacity: 1;
                transform: translate3d(0, 0, 0);
              }
            }
          }

          &:hover {
            .dropdown-menu-list {
              transform: translateY(0);
              opacity: 1;
            }
          }
        }
      }
    }

    .promos-m-list {
      display: grid;
      padding: 1rem;

      .promos-m {
        width: 100%;
        position: relative;
        overflow: hidden;
        // box-shadow: $slate-gray-card-shadow;
        border-radius: 0.5rem;

        .lazy-load-image-background {
          height: 100%;
          width: 100%;
        }

        .promos-m-image {
          width: 100%;
          height: auto;
          // object-fit: cover;
          // object-position: center;
        }

        .promos-m-title {
          position: absolute;
          top: 0;
          left: 0;
          width: 30%;
          height: auto;
        }

        .promos-m-content {
          width: 100%;
          height: auto;
          min-height: 5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: $dark-gray-600;
          padding: 0.75rem;

          p {
            font-size: $text-16;
            color: $slate-gray-800;
          }

          .promos-date {
            display: flex;
            align-items: center;
            font-size: $text-12;
            color: $slate-gray-300;
            span{
              color:$gray-50;
              img{
                width:  0.75rem;
                height:  0.75rem;
                margin-right: 0.25rem;
              }
            }

            svg {
              width: 2rem;
              height: 2rem;
              margin-right: 0.5rem;
            }
          }
        }

        // .btn-outline-blue {
        //   position: absolute;
        //   left: 1.5rem;
        //   bottom: 6rem;
        //   border-radius: 99px;
        //   height: 2rem;
        //   border: 2px solid;
        //   background-color: $dark-gray-600;
        // }
      }
    }
  }

  //promos-detail mobile scss
  .promos-detail-m-header {
    .m-subHeader {
      h5 {
        width: unset;
      }
    }
  }