.responsible-gaming-section {
  .section-content {
    background-color: unset;
    border-radius: .7rem;
    box-shadow: $slate-black-shadow;
    // border: 1.5px solid $black-500;
    padding: 3rem 5rem 5rem 5rem;
    h3 {
      color: $blue-500;
      padding-top: 2rem;
    }
    ul {
      margin-left: -1rem;
      li::marker {
        font-size: 0.6rem;
      }
    }
    &.mobile {
      padding: 1rem;
      border-radius: unset;
      h3 {
        // padding-top: 0rem;
        font-size: $text-16;
      }
    }
  }
  .m-subHeader {
    h5 {
      width: unset;
    }
  }
}

//mobile
