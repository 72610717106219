.bne-section,
.bne-m-section {
  .bne-total-amount {
    position: relative;
    .total-amount-header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      border: rem(1px) solid $slate-gray-200;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      background-color: $slate-gray-100;
      img {
        width: rem(59px);
        height: auto;
        margin-right: 1rem;
      }

      h3 {
        color: $slate-gray-800;
        font-weight: $font-semibold;
        margin-left: 1rem;
        margin-bottom: 0;
        text-align: center;
      }
    }
    .total-amount-content {
      min-height: 18rem;
      background: white;
      border: 1px solid $slate-gray-200;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      .receive-state {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .number {
        font-size: $text-16;
        color: $slate-gray-800;
        margin-left: 0.25rem;
      }
      .btn {
        width: 100%;
        margin-top: 3rem;
      }
      .total-amount-mention {
        font-size: $text-12;
        text-align: center;
        margin-top: 0.25rem;
      }
    }
    &.card-shadow {
      overflow: unset;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -7.2%;
      left: 16%;
      background: $slate-gray-300;
      padding: 1rem 0.5rem;
      z-index: -1;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: -7.2%;
      right: 16%;
      background: $slate-gray-300;
      padding: 1rem 0.5rem;
      z-index: -1;
    }
  }
  .events-table-container {
    margin-top: 1.5rem;
    .events-table {
      tbody {
        .btn {
          min-width: 5.5rem;
          height: 1.5rem;
          line-height: 0;
        }
      }
    }
  }
}

.bne-section {
  .section-header-gray {
    p {
      font-size: $text-24;
    }
  }
  .term-section {
    .term-content {
      h4 {
        color: $slate-gray-800;
        padding-left: 2rem;
      }
    }
  }
}
//Mobile bne-section

.bne-m-section {
  .game-m-nav {
    .game-nav-btn {
      justify-content: center;
      .nav-img {
        width: 2rem;
        margin-right: 1rem;
      }
      p {
        width: auto;
      }
    }
  }
  .bne-total-amount {
    .total-amount-header {
      justify-content: center;
      h3 {
        width: auto;
      }
    }
    &::after {
      bottom: -7%;
    }
    &::before {
      bottom: -7%;
    }
  }
  .term-section {
    .bet-tnc-title {
      margin: 1rem 0;
      padding: 0.75rem;
      background-color: $slate-gray-200;
      color: white;
      text-align: center;
    }
    .term-container {
      background-color: unset;
      padding: 1rem;
    }
    .term-content {
      background-image: unset;
      padding: 0;
      h4 {
        color: $slate-gray-800;
        padding-left: 2rem;
      }
    }
  }
}
