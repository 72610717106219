.main-asset,
.asset-1,
.asset-2,
.asset-3 {
  width: 100%;
  height: auto;
}

.asset-1{
  position: absolute;
  animation: top 3s infinite ease-in-out;
}
.asset-2{
  position: absolute;
  animation: top 4s infinite ease-in-out;
}
.asset-3 {
  position: absolute;
  animation: top 5s infinite ease-in-out;
}

//SPORT START------------------------------------------------------------------------------------------------------------------------------------
.sport-sb-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(125px);
  width: 100%;
  .main-asset {
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 90px;
    left: 12.5%;
    top: 8%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 80px;
    bottom: 26%;
    right: 2.5%;
    z-index: 1;
  }
}

.sport-st-container {
  position: absolute;
  height: auto;
  right: -5%;
  bottom: rem(125px);
  width: 100%;
  .main-asset {
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 90px;
    left: 7.5%;
    bottom: 22%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 80px;
    top: 10%;
    right: 7.5%;
    z-index: 1;
  }
}

.sport-im-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(125px);
  width: 100%;
  .main-asset {
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 80px;
    left: 7.5%;
    top: 8%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 90px;
    bottom: 15%;
    right: 0%;
    z-index: 1;
  }
}

.sport-bti-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(125px);
  width: 100%;
  .asset-1 {
    max-width: 90px;
    left: 5%;
    bottom: 25%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 80px;
    top: 7.5%;
    right: 2.5%;
    z-index: 1;
  }
}

//SPORT END------------------------------------------------------------------------------------------------------------------------------------

//ESPORTS START------------------------------------------------------------------------------------------------------------------------------------
.esports-tfg-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(150px);
  width: 90%;
  .asset-1 {
    max-width: 120px;
    right: 2.5%;
    top: 25%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 85px;
    bottom: 25%;
    left: 2.5%;
    z-index: 1;
  }
}

.esports-im-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(150px);
  width: 90%;
  .asset-1 {
    max-width: 100px;
    right: 10%;
    top: 37.5%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 165px;
    bottom: 25%;
    left: 2.5%;
    z-index: 1;
  }
}

//ESPORTS END------------------------------------------------------------------------------------------------------------------------------------

//LIVE CASINO START------------------------------------------------------------------------------------------------------------------------------------
.lc-game-container {
  .content-img {
    max-width: 300px;
  }
}

.lc-ag-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(50px);
  width: 100%;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 75px;
    right: 8%;
    top: 10%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 105px;
    top: 33%;
    left: 18%;
    z-index: 1;
  }
}

.lc-dg-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(50px);
  width: 100%;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 80px;
    right: 10%;
    top: 11%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 90px;
    top: 58%;
    left: 12%;
    z-index: 1;
  }
}

.lc-ea-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(50px);
  width: 100%;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 80px;
    right: 12%;
    top: 16%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 90px;
    top: 6%;
    left: 22%;
    z-index: 1;
  }
}

.lc-gp-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(50px);
  width: 100%;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 50px;
    right: 12%;
    top: 30%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 85px;
    top: 14%;
    left: 14%;
    z-index: 1;
  }
}

.lc-pp-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(50px);
  width: 100%;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 80px;
    right: 12%;
    top: 24%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 75px;
    top: 15%;
    left: 11%;
    z-index: 1;
  }
}

.lc-sa-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(50px);
  width: 100%;
  padding-right: 2rem;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 75px;
    right: 14%;
    top: 14%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 75px;
    top: 56%;
    left: 10%;
    z-index: 1;
  }
}

.lc-eg-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(0px);
  width: 100%;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 75px;
    right: 8%;
    top: 14%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 75px;
    top: 50%;
    left: 11%;
    z-index: 1;
  }
}

.lc-st-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(50px);
  width: 100%;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 75px;
    right: 18%;
    top: 9%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 75px;
    top: 35%;
    left: 7%;
    z-index: 1;
  }
}

.lc-ab-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(50px);
  width: 100%;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 75px;
    right: 8%;
    top: 35%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 55px;
    top: 8%;
    left: 13%;
    z-index: 1;
  }
}

.lc-pt-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(50px);
  width: 100%;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 75px;
    right: 11%;
    top: 21%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 75px;
    top: 17%;
    left: 10%;
    z-index: 1;
  }
}

.lc-mg-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: rem(50px);
  width: 100%;
  .main-asset {
    bottom: -5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 75px;
    right: 18%;
    top: 20%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 75px;
    top: 35%;
    left: 6%;
    z-index: 1;
  }
}

//LIVE CASINO END------------------------------------------------------------------------------------------------------------------------------------

//GAMES START------------------------------------------------------------------------------------------------------------------------------------
.game-ib-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 100px;
  width: 80%;
  .asset-1 {
    max-width: 85px;
    left: 0;
    top: 38%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 65px;
    top: 14%;
    right: 0;
    z-index: 1;
  }

  .asset-3 {
    max-width: 75px;
    bottom: 40%;
    right: 0;
    z-index: 1;
  }
}

.game-pp-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 100px;
  width: 80%;
  .asset-1 {
    max-width: 90px;
    left: -4%;
    top: 30%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 70px;
    top: 30%;
    right: 0;
    z-index: 1;
  }

  .asset-3 {
    max-width: 100px;
    bottom: 26%;
    right: 0;
    z-index: 1;
  }
}

.game-qt-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 100px;
  width: 80%;
  .asset-1 {
    max-width: 85px;
    left: -8%;
    top: 18%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 70px;
    top: 32%;
    right: -2%;
    z-index: 1;
  }

  .asset-3 {
    max-width: 100px;
    bottom: 24%;
    left: -2%;
    z-index: 1;
  }
}

.game-sb-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 100px;
  width: 80%;
  .asset-1 {
    max-width: 100px;
    left: 12%;
    top: 6%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 90px;
    bottom: 38%;
    right: 0;
    z-index: 1;
  }

  .asset-3 {
    max-width: 65px;
    bottom: 36%;
    left: 0;
    z-index: 1;
  }
}

.game-sp-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 100px;
  width: 80%;
  .asset-1 {
    max-width: 75px;
    left: -2%;
    top: 10%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 85px;
    bottom: 32%;
    left: -10%;
    z-index: 1;
  }

  .asset-3 {
    max-width: 110px;
    bottom: 28%;
    right: -2%;
    z-index: 1;
  }
}

.game-st-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 100px;
  width: 80%;
  .asset-1 {
    max-width: 60px;
    left: 6%;
    top: -2%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 80px;
    bottom: 36%;
    left: -4%;
    z-index: 1;
  }

  .asset-3 {
    max-width: 115px;
    bottom: 28%;
    right: -2%;
    z-index: 1;
  }
}

.game-mg-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 100px;
  width: 80%;
  .asset-1 {
    max-width: 60px;
    left: 30%;
    top: -2%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 80px;
    bottom: 46%;
    right: -8%;
    z-index: 1;
  }

  .asset-3 {
    max-width: 100px;
    bottom: 30%;
    left: 0;
    z-index: 1;
  }
}
//GAMES END------------------------------------------------------------------------------------------------------------------------------------

//LOTTERY START------------------------------------------------------------------------------------------------------------------------------------

.lottery-gp-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 100px;
  width: 110%;
  .asset-1 {
    max-width: 75px;
    left: 37%;
    top: 10%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 70px;
    top: 25%;
    right: 0;
    z-index: 1;
  }

  .asset-3 {
    max-width: 65px;
    top: 32%;
    left: 50%;
    z-index: 1;
  }
}

.lottery-qt-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 100px;
  width: 110%;
  .asset-1 {
    max-width: 105px;
    left: 28%;
    top: 18%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 60px;
    top: 28%;
    right: 8%;
    z-index: 1;
  }
  .asset-3 {
    max-width: 40px;
    top: 40%;
    left: 40%;
    z-index: 1;
  }
}

.lottery-sb-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 100px;
  width: 100%;
  .main-asset {
    padding-top: 5rem;
    object-fit: scale-down;
  }
  .asset-1 {
    max-width: 105px;
    left: 14%;
    top: 10%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 65px;
    top: 16%;
    right: 8%;
    z-index: 1;
  }
  .asset-3 {
    max-width: 50px;
    top: 28%;
    left: 26%;
    z-index: 1;
  }
}
//LOTTERY END------------------------------------------------------------------------------------------------------------------------------------

//P2P START------------------------------------------------------------------------------------------------------------------------------------
.p2p-gp-container {
  position: absolute;
  height: auto;
  right: 0;
  bottom: 0;
  width: 115%;
  .asset-1 {
    max-width: 80px;
    left: 10%;
    top: 14%;
    z-index: 1;
  }
  .asset-2 {
    max-width: 60px;
    top: 20%;
    right: 20%;
    z-index: 1;
  }

  .asset-3 {
    max-width: 70px;
    bottom: 20%;
    left: 0;
    z-index: 1;
  }
}
//P2P END------------------------------------------------------------------------------------------------------------------------------------
