.ls-m-section,
.ls-section {
  .winner-list {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $slate-gray-400;
    margin-bottom: .75rem;
    svg {
      font-size: $text-24;
    }
    h2 {
      font-weight: $font-semibold;
      margin: 0 0.75rem;
    }
  }
  .events-table-container {
    .events-table {
      thead {
        display: table;
        width: 100%;
        tr {
          th {
            width: 50%;
          }
        }
      }
      tbody {
        display: block;
        height: rem(600px);
        overflow: auto;
        background: white;
        &::-webkit-scrollbar {
          display: none;
        }
        tr {
          display: table;
          width: 100%;
          box-sizing: border-box;
          td {
            width: 50%;
            &:first-child {
              max-width: 8rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.ls-section {
  .ls-container {
    .row {
      .col-9 {
        .ls-wheel-container {
          display: flex;
          position: relative;
          justify-content: center;
          .ls-wheel {
            width: 65%;
            height: auto;
          }
          .ls-arrow {
            position: absolute;
            font-size: 5rem;
            top: -22px;
            color: red;
            z-index: 1;
          }
          .wheel-btn {
            position: absolute;
            z-index: 10;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            img {
              width: 6.5rem;
              height: auto;
              transition: all 0.3s ease-in-out;
            }
            .wheel-btn-text {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              max-width: 6.25rem;
              text-align: center;
              color: white;
              h2 {
                margin: 0;
              }
            }
            &:hover {
              border-radius: 50%;
              animation: pulse 1s;
              box-shadow: 0 0 0 2em transparent;
            }
          }
        }
        .ls-total-coin {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 1rem;
          img {
            width: rem(60px);
            height: rem(60px);
            margin-right: 0.5rem;
          }
          p {
            font-size: $text-16;
            span {
              color: $slate-gray-800;
            }
          }

          small {
            font-size: $text-10;
          }
        }
        .ls-btn-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1rem;
          .btn {
            width: 35%;
            margin: 0 1rem;
          }
        }
      }
    }
  }
}
.sc-gsTCUz.bhdLno {
  transform: rotate(-45deg);
  width: 80%;
  height: auto;
  max-width: unset;
  max-height: unset;
  overflow: hidden;
  .sc-dlfnbm {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      // background-image: url("../../../../images/lucky-spin/wheel.webp");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(79deg);
      z-index: -1;
    }
    canvas {
      position: relative;
      transform: scale(0.9);
      opacity: 0;
    }
  }
  img[alt~="roulette-static"] {
    position: absolute;
    transform: rotate(45deg);
    z-index: 5;
    width: 10%;
    right: 80px;
    top: 80px;
    content: url("../../../../images/lucky-spin/pointer.webp");
  }
}
.ls-wheel-container:not(.indon-my) {
  .sc-dlfnbm::after {
    background-image: url("../../../../images/lucky-spin/wheel.webp");
  }
}
.ls-wheel-container.indon-my {
  .sc-dlfnbm::after {
    background-image: url("../../../../images/lucky-spin/wheel-indon.webp");
  }
}
//mobile
.mobile-view {
  .sc-gsTCUz.bhdLno {
    width: 100%;
    img[alt~="roulette-static"] {
      right: 2.5rem;
      top: 2.5rem;
    }
  }
  .ls-modal-body {
    // position: relative;
    .ls-modal {
      padding-top: 0;
      img {
        width: 4.5rem;
        position: absolute;
        bottom: 84%;
        left: 50%;
        transform: translateX(-50%);
      }
      .prize-type {
        padding-top: 1.5rem;
        margin-bottom: -1rem !important;
      }
    }
  }

  .ls-m-section {
    .ls-m-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-x: hidden;
      z-index: 0;
      .ls-wheel-container {
        width: 100%;
        display: flex;
        position: relative;
        justify-content: center;
        margin-top: 1rem;
        .ls-wheel {
          width: 100%;
          height: auto;
        }
        .ls-arrow {
          position: absolute;
          font-size: 3rem;
          top: -22px;
          color: red;
          z-index: 1;
        }
        .wheel-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
          cursor: pointer;
          img {
            width: 4.5rem;
            height: auto;
          }
          .wheel-btn-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 92%;
            text-align: center;
            color: white;
            h2 {
              font-weight: $font-semibold;
              font-size: $text-20;
              margin: 0;
            }
            p {
              font-size: $text-10;
            }
          }
        }
      }
      .ls-total-coin {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $slate-gray-800;
        line-height: 1.2;
        img {
          width: rem(46px);
          height: rem(46px);
          margin-right: 0.5rem;
        }
        p {
          font-size: $text-18;
        }
        small {
          font-size: $text-12;
        }
      }
      .ls-btn-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        .btn {
          width: 48%;
        }
      }
    }
  }
}

.ls-modal {
  display: grid;
  padding-top: 1rem;
  text-align: center;
  img {
    width: 6rem;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  h4 {
    color: $slate-gray-800 !important;
  }
  .prize-type {
    font-size: $text-24;
    color: $slate-gray-400;
    font-weight: $font-medium;
  }
  .prize-name {
    color: $slate-gray-500;
    font-weight: $font-semibold;
  }
  .btn {
    display: block;
    margin: 0 auto;
    width: 50%;
    transition: box-shadow 0.3s ease-in-out;
  }
}

.ls-btn-container {
  .btn-success {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    background-color: $green-500;
    border-color: $green-500;
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
      box-shadow: 0 0 0 0.25rem rgba($green-500, 0.25);
    }
  }
}
