.live-streaming-section {
  .section-content {
    width: 100%;
    min-height: rem(610px);
    background-image: url("../../../images/home/video-bg.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: bottom;
    padding: 1rem;
  }
  .live-stream-match {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: rem(33px);
      height: rem(30px);
    }
    .team {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: rem(40px);
        height: rem(40px);
      }
    }
  }
  .live-stream-video {
    position: relative;
    iframe {
      border-radius: 0.75rem;
    }
  }
  .live-stream-result {
    .row {
      .col-10 {
        display: flex;
        justify-content: space-between;
        .result-container {
          width: max-content;
          .result-type {
            position: relative;
            color: $blue-500;
            text-align: center;
            span {
              background-color: #e6edf8;
              width: auto;
              display: inline-block;
              z-index: 3;
              padding: 0 0.5rem 0 0.5rem;
              color: $blue-500;
              position: relative;
              font-family: calibri;
              font-weight: lighter;
              margin: 0;
            }
            &::after {
              content: "";
              width: 100%;
              border-bottom: solid rem(2px) $blue-500;
              position: absolute;
              left: 0;
              top: 50%;
              z-index: 1;
            }
          }
          .result {
            background: white;
            padding: 0.25rem 0.875rem;
            border: rem(1px) solid $slate-gray-300;
            border-radius: 0.5rem;
            margin-right: 0.5rem;
            text-align: center;
            &:last-child {
              margin-right: 0;
            }
            p{
              line-height: 1.2;
            }
            span {
              color: $slate-gray-800;
            }
          }
        }
      }
      .more-btn-blue {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .lsv-options-container {
    width: 100%;
    height: calc(38.125rem - 2rem);
    overflow: auto;
    padding: 1rem;
    .live-stream-options {
      position: relative;
      width: 100%;
      max-height: rem(96px);
      padding: 1rem;
      border-radius: 0.75rem;
      background-color: white;
      margin-bottom: 1rem;
      transition: all 0.25s ease-in-out;
      cursor: pointer;
      &.active {
        background-image: url("../../../images/live-streaming/lv-active-bg.webp");
        background-position: bottom;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-shadow: 0 0 0 0.125rem rgba($blue-500,1);
      }
      &:hover {
        box-shadow: 0 0 0 0.125rem rgba($blue-500,1);
      }
      .match-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $slate-gray-800;
        line-height: 1;
        .match-date {
          display: flex;
          align-items: center;
          font-size: $text-12;
          color: $slate-gray-400;
          img {
            width: rem(12px);
            height: rem(12px);
            margin-left: 1rem;
          }
        }
      }
      .live-stream-match {
        .team {
          img {
            width: rem(25px);
            height: rem(25px);
          }
        }
      }
    }
    &::-webkit-scrollbar-track {
      background: $slate-gray-200;
    }
    &::-webkit-scrollbar {
      width: rem(4px);
    }
    &::-webkit-scrollbar-thumb {
      background: $blue-500;
    }
  }
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 0.75rem;
    background-color: rgba(0, 0, 0, 0.75);
    text-align: center;
    z-index: 2;
  }
}

.mobile-view {
  .live-streaming-section {
    .lsv-options-container {
      overflow: unset;
    }
    .live-stream-result {
      background: url("../../../images/live-streaming/lv-active-bg.webp");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: bottom;
      border-radius: 0.75rem;
      h5 {
        background-color: white;
        width: 100%;
        text-align: center;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        padding: 0.5rem;
        margin-bottom: 0rem;
      }
      .row {
        justify-content: center;
        .col-3 {
          border: rem(1px) solid $slate-gray-200;
          border-radius: 0.5rem;
          text-align: center;
          padding: 0.5rem;
          background: white;
          P{
            line-height: 1.2;
          }
          span {
            color: $slate-gray-800;
          }
        }
      }
    }
    .overlay{
      border-radius: unset;
    }
  }
}
