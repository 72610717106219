//Vip Exclusive//
.vip-lvl-col {
  .vip-lvl-tp {
    display: flex;
    @include shineEffect;
    .lazy-load-image-background {
      margin-top: -25px;
    }
    img {
      max-width: 100px;
      width: 100%;
      height: auto;
    }
    .vip-lvl-details-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding-left: 1rem;
      h5 {
        color: $slate-gray-800;
      }
      .vip-lvl-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          display: flex;
          & > *:nth-child(n + 1):not(:last-child) {
            margin-right: 2rem;
          }
        }
        a {
          display: flex;
          align-items: center;
          width: unset;
        }
      }
    }
  }
  .vip-lvl-md {
    position: relative;
    margin: 2rem 1rem;
    height: 12px;
    background-color: $dark-gray-800;
    border-radius: 0.5rem;
    ul {
      list-style-type: none;
      padding-left: unset;
      display: flex;
      li {
        position: relative;
        height: 12px;
        flex: 1 1;
        &.active {
          span {
            top: -42px;
            width: 60px;
            height: 60px;
            line-height: 4.5;
            text-align: center;
            color: $slate-gray-200;
            background: url("../../../../images/profile/icons/cloud-pointer.svg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            pointer-events: none;
          }
        }
        span {
          position: absolute;
          top: -25px;
          right: -15px;
          font-size: $text-12;
          font-weight: $font-medium;
          white-space: nowrap;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
    .vip-lvl-progress-bar {
      height: 12px;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.5s ease-in-out;
      border-radius: 0.5rem;
      background: linear-gradient(to right, $blue-500, $blue-600);
      // box-shadow: $slate-gray-shadow;
    }
  }
  .vip-lvl-bt {
    display: grid;
    gap: 1rem;
    h6 {
      font-weight: $font-regular;
      color: $blue-500;
      margin: 0;
    }
    .vip-lvl-deposit,
    .vip-lvl-turnover {
      display: flex;
      align-items: center;
      .progress-bar {
        position: relative;
        height: 8px;
        width: 35%;
        background-color: $dark-gray-800;
        border-radius: 0.5rem;
        margin: 0 1rem;
        span {
          height: 8px;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 0.5rem;
          background: linear-gradient(to right, $blue-500, $blue-600);
          // box-shadow: $slate-gray-shadow;
          transition: all 0.5s ease-in-out;
        }
      }
      p {
        &:first-child {
          min-width: 70px;
        }
        &:last-child {
          color: $blue-500;
        }
      }
    }
  }
}
.vip-exclusive-col {
  .col-5ths {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    text-align: center;
    img {
      max-width: 75px;
      width: 100%;
    }
    p {
      font-size: $text-12;
      margin-bottom: 0.5rem !important;
      small {
        display: block;
        .number {
          color: $blue-500;
        }
      }
    }
    .btn-outline-blue {
      height: rem(25px);
      min-width: unset;
      line-height: 0;
      margin-top: auto;
      border-radius: rem(15px);
      &:hover {
        color: $blue-500;
        box-shadow: $blue-hover-shadow;
      }
    }
  }
}
.vip-offer-col {
  .row {
    .col-5ths {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      // &:not(:last-child) {
      //   border-right: 1px solid $slate-gray-200;
      // }
      h5 {
        color: $blue-500;
        font-size: $text-14;
      }
      p {
        margin-top: 0.25rem;
        font-size: $text-16;
        color: $slate-gray-800;

        small {
          display: block;
        }
      }
    }
  }
}

.vip-offer-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  background: $dark-gray-800;
  border-radius: 0.5rem;
  border: rem(2px) solid $dark-gray-600;
  img {
    max-width: 5rem;
    width: 100%;
    height: auto;
  }
}

//mobile vip exclusion
.ve-m-container {
  color: $dark-gray-800;
  .ve-m-header {
    width: 100%;
    height: auto;
    background-image: url("../../../../images/profile/mobile/header-bg.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .pi-m-info {
      width: 100%;
      height: auto;
      padding: 0 1rem;
      justify-content: space-between;
      .user-info {
        width: 80%;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0;
      }
    }
    .dt-container {
      padding: 0 1rem;
      .dt-amount {
        display: flex;
        margin-top: 1rem;
        .deposit,
        .turnover {
          width: rem(90px);
          height: auto;
          color: $slate-gray-800;
        }
        .progress {
          .back,
          .front {
            justify-content: center;
          }
          .back {
            background: linear-gradient(to right, $blue-400 10%, $blue-600);
          }
          .front {
            color: $blue-500;
          }
        }
      }
    }
    .slick-container {
      .slick-track {
        padding-bottom: 1rem;
        .slick-slide {
          padding: 0 0.5rem;
        }
      }
      .ve-vip-card {
        position: relative;
        .card-shadow {
          border-radius: rem(10px);
        }
        .vip-state {
          position: absolute;
          top: 0;
          left: 5%;
          padding: 0.25rem 2.5rem;
          border-bottom-left-radius: rem(10px);
          border-bottom-right-radius: rem(10px);
          font-size: $text-12;
          color: $dark-gray-700;
          text-transform: uppercase;
          background: linear-gradient(
            to right,
            $slate-gray-500,
            $slate-gray-800
          );
          &.current {
            background: linear-gradient(to top, $blue-600, $blue-500);
          }
        }
        .vip-logo {
          width: 100%;
          max-width: rem(90px);
          height: auto;
          position: absolute;
          top: 0;
          right: 1rem;
          z-index: 1;
        }
        .vip-content-container {
          width: 100%;
          height: auto;
          position: absolute;
          top: 12%;
          left: 0;
          .vip-content {
            padding: 0.5rem 2rem;
            margin-right: rem(48px);
            font-size: $text-12;
            color: white;
            span {
              color: $blue-500;
            }
            h1 {
              font-size: $text-30;
              margin: 0;
            }
          }
          .vip-notice {
            width: 100%;
            height: auto;
            // background: linear-gradient(
            //   to right,
            //   $slate-gray-300,
            //   $slate-gray-200
            // );
            background: $dark-gray-600;
            padding: 0.5rem 2rem;
            font-size: $text-10;
            // filter: opacity(1);
            color:white;
            span {
              color: $blue-500;
            }
          }
        }
      }
    }
  }

  .ve-m-content {
    .my-ve,
    .my-detail {
      padding: 1rem; 
      background: $dark-gray-700;
      box-shadow: $slate-black-shadow;
      color: $slate-gray-800;
      .row {
        .col-6 {
          display: flex;
          align-items: flex-start;
        }
      }
      h5 {
        margin-bottom: 1.5rem;
      }
    }

    .my-ve {
      .row {
        .col-6 {
          img {
            min-width: rem(55px);
            height: auto;
          }
          div {
            p {
              font-size: $text-18;
            }
            .exclusive {
              font-size: $text-12;
              color: $slate-gray-500;
              .number {
                color: $blue-500;
              }
            }
          }
          .btn {
            min-width: rem(52px);
            height: rem(24px);
            font-size: $text-10;
            padding: 0 0.5rem;
            border-radius: 1rem;
            margin-top: 0.25rem;
          }
        }
      }
    }
    .my-detail {
      .col-6 {
        .vip-offer-img {
          width: rem(40px);
          padding: 0;
          margin: 0;
        }
        div {
          width: 70%;
          display: flex;
          flex-direction: column;
          margin-left: 0.5rem;
          p {
            font-size: $text-12;
            color: $blue-500;
          }
          .number {
            font-size: $text-18;
            color: $slate-gray-800;
          }
        }
      }
    }
  }

  .progress {
    width: 100%;
    height: 1.25rem;
    position: relative;
    background-color: $dark-gray-800;
    border-radius: 0.75rem;
    .front,
    .back {
      width: 100%;
      height: 1.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.5rem;
      font-weight: $font-semibold;
      //  font-style: italic;
    }
    .front {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $dark-gray-800;
      color: $brown-500;
      transition: clip-path 1s linear;
    }
    .back {
      background: linear-gradient(to right, $brown-500 10%, $brown-300);
      color: $dark-gray-800;
    }
  }
}
