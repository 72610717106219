// Profile Dashboard Table //
.pd-table {
  width: 100%;
  margin-bottom: 1rem;
  &.w-border {
    border-collapse: separate;
    border-spacing: 0 1rem;
    tbody {
      tr {
        overflow: hidden;
        td {
          font-weight: $font-medium;
          border-top: rem(1px) solid $slate-gray-200;
          vertical-align: middle;
          &:first-child {
            border-left: rem(1px) solid $slate-gray-200;
            border-radius: 0.5rem 0 0 0.5rem;
          }
          &:last-child {
            border-right: rem(1px) solid $slate-gray-200;
            border-radius: 0 0.5rem 0.5rem 0;
          }
          img {
            width: rem(25px);
            height: rem(25px);
            object-fit: contain;
            margin-right: rem(14px);
          }
        }
      }
    }
  }
  thead {
    tr {
      background-color: $dark-gray-700;;
      text-align: center;
      th {
        font-size: $text-12;
        font-weight: $font-semibold;
        color: $slate-gray-800;
        padding: 0 1rem;
        height: rem(50px);
        vertical-align: middle;
        // line-height: 50px;
        border-bottom-width: 0;
            background: $dark-gray-800;
      }
    }
  }
  tbody {
    border-top: 0 !important;
    tr {
      td:not(.msg-list-group):not(.mall-track-title) {
        font-size: $text-12;
        text-align: center;
        padding: 0.75rem 1rem;
        line-height: 2;
        border-bottom: rem(1px) solid $slate-gray-200;
        vertical-align: middle;
        white-space: nowrap;
        background-color: unset;
        .on-going {
          // width: 50%;
          display: inline-block;
          color: $orange-500;
        }
        .success {
          // width: 50%;
          display: inline-block;
          color: $green-500;
        }
        .failed {
          // width: 50%;
          display: inline-block;
          color: $red-500;
          svg {
            margin: 0 0.25rem 0.1rem 0;
          }
        }
        .btn-action {
          color: $blue-500;
          font-size: $text-12;
          padding: 0.25rem 0.5rem;
          cursor: pointer;
          &:hover {
            box-shadow: $blue-hover-shadow;
          }
          &.no-hover {
            &:hover {
              box-shadow: unset;
            }
          }
        }
      }
    }
  }
}
.pf-m-dashboard {
  .react-bootstrap-table {
    overflow-x: auto;
    @include hideScrollbars;
    .pd-table {
      // width: 100vw;
      thead {
        tr {
          box-shadow: $slate-black-shadow;
          th {
            // background: linear-gradient(#fff, $slate-gray-100);
            background: $dark-gray-700;
            border: 0!important;
            text-align: center;
            min-width: rem(110px);
            font-weight: $font-medium;
            line-height: $leading-tight;
          }
        }
      }
      tbody {
        tr {
          overflow: unset;
          td {
            text-align: center;
            min-width: rem(110px);
            line-height: $leading-normal;
            .status {
              width: unset;
            }
          }
        }
      }
    }
  }
  .pagination-col {
    padding: 1rem;
    .pagination {
      margin-top: unset;
      // margin-bottom: 1rem;
    }
  }
}
// Pagination //
.pagination-col {
  margin-top: auto;
  .pagination {
    margin-top: 1rem;
    margin-bottom: 0;
    justify-content: center;
    .page-item {
      &.active .page-link {
        color: white;
        border: 0;
        background: linear-gradient(to bottom, $blue-400, $blue-500);
      }
      &:hover .page-link {
        background-color: unset;
        box-shadow: $blue-hover-shadow;
      }
      .page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5rem;
        margin: 0.25rem;
        width: rem(25px);
        height: rem(25px);
        padding: 0;
        color: $slate-gray-800;
        border-color: rem(1px) solid $dark-gray-600;
        font-size: $text-12;
        background-color:$dark-gray-700;
        &[disabled] {
          opacity: 50%;
          pointer-events: none;
          box-shadow: unset;
        }
      }
    }
  }
}

//events table
.events-table-container {
  border-radius: 1rem;
  border: rem(1px) solid $slate-gray-200;
  .events-table {
    text-align: center;
    margin: 0;
    thead {
      th {
        padding: 1rem 0.5rem;
        background: $slate-gray-100;
      }
    }
    tbody {
      tr {
        font-size: $text-12;
        background: $slate-gray-100;
        vertical-align: middle;
        &:nth-child(odd) {
          background: white;
        }
        td {
          border: 0;
        }
      }
      .reward {
        color: $orange-500;
      }
    }
  }
}

.term-section {
  .table {
    text-align: center;
    box-shadow: $slate-gray-shadow;
    table-layout: fixed;
    thead {
      tr {
        th {
          border-right: rem(1px) solid $slate-gray-200;
          background-color: $slate-gray-100;
          font-size: $text-16;
          font-weight: $font-semibold;
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          color: $slate-gray-500 !important;
          font-size: $text-16;
        }
        &:last-child {
          td {
            border-bottom-width: 0 !important;
          }
        }
        &:nth-child(even) {
          td {
            background-color: $slate-gray-100;
          }
        }
        &:nth-child(odd) {
          td {
            background-color: white !important;
          }
        }
      }
    }
  }
  &.christmas{
    tbody {
      tr {
        td {
        &:nth-child(2) {
            color: $blue-600 !important;
          }
        }

      }
    }
  }
  &.cny{
    tbody {
      tr {
        td {
        &:last-child {
            color: $blue-600 !important;
          }
        }

      }
    }
  }
}

.mobile-view {
  .term-section {
    margin: 1rem 0;
    background-color: $dark-gray-700;
    border-radius: 0.75rem;
    &.no-bg {
      background-color: unset;
    }
    .tnc-title {
      margin: 1rem 0;
      padding: 0.75rem;
      background-color: $slate-gray-200;
      color: white;
      text-align: center;
    }
    .term-container {
      background-color: $dark-gray-700;
      border: rem(2px) solid $dark-gray-600;
      &.no-bg {
        background-color: unset;
      }
    }
    .term-content {
      &.no-bg {
        background-image: unset !important;
        padding: 0 !important;
      }
      h4 {
        color: $slate-gray-800;
        padding: 0rem;
      }
    }
    .table {
      thead {
        tr {
          th {
            font-size: $text-16;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: $text-14;
          }
        }
      }
    }
  }
}
