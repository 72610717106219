// My Wallet //
.my-wallet-modal {
  padding: 1rem;
  .modal-header {
    padding: 0;
    background-color: $dark-gray-700;
    h5 {
      color: white !important;
      margin: 0;
    }
  }
  .modal-body {
    padding: 0;
    background-color: $dark-gray-700;
    h6{
      color: $slate-gray-800;
      font-weight: $font-semibold;
    }
    .my-wallet-modal-content {
      padding-left: 1rem;
      margin-bottom: 0;
      height: 16rem;
      overflow: auto;
      li{
        margin-bottom: 1rem;
      }
    }
    .btn-gradient-blue{
      min-width: 16rem;
      display: block;
      margin: 0 auto;
    }
  }
}

.my-wallet-col {
  .row {
    .col-6 {
      .my-wallet-content {
        height: 100%;
        display: flex;
        padding: 1rem;
        border: 1px solid $slate-gray-200;
        border-radius: 0.5rem;

        .wallet-container,
        .coin-container,
        .interest-container {
          width: 60%;
          display: flex;
          align-items: center;
          img,
          svg {
            margin-right: 7px;
          }
          p {
            color: $slate-gray-800;
            font-weight: $font-medium;
            span {
              font-size: $text-20;
              word-break: break-all;
            }
          }
        }
        .coin-container {
          svg {
            border: rem(1px) solid $slate-gray-200;
            border-radius: 1rem;
            width: rem(32px);
            height: auto;
            color: $slate-gray-200;
            cursor: pointer;
          }
        }
        .interest-container {
          width: 40%;
          flex-direction: column;
          // justify-content: center;
          border-left: rem(1px) solid $slate-gray-200;
          span {
            color: $green-500;
          }
        }
      }
    }
    .col-6:nth-child(2) > a {
      display: block;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border-radius: 0.5rem;
      overflow: hidden;
      &:hover {
        box-shadow: $blue-hover-shadow;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
.fd-col {
  .nav-tabs {
    border-bottom: rem(1px) solid $slate-gray-200;
    .nav-item {
      &:nth-child(n + 1):not(:last-child) {
        margin-right: 1rem;
      }
      .nav-link {
        color: $slate-gray-800;
        font-weight: $font-semibold;
        outline: 0;
        border: 0;
        padding-left: 0;
        padding-right: 0;
        &.active {
          color: $blue-500;
          border: 0;
          border-bottom: rem(2px) solid $blue-500;
          background: unset;
        }
      }
    }
  }
  .nav-pills{
    background: $dark-gray-800;
    border: rem(2px) solid $dark-gray-600;
    padding: 0.25rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    .nav-item{
      width: 50%;
    }
    .nav-link{
      color: $gray-50;
      width: 100%;

      &.active{
        background: $dark-gray-700;
        border: rem(2px) solid $dark-gray-600;
        color: $slate-gray-800;
        border-radius: 0.25rem;
      }
    }
   
  }
}
.add-more-col {
  &.show {
    height: auto;
    opacity: 1;
    overflow: unset;
  }
  height: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  .add-more-headers {
    font-size: 0.75rem;
    font-weight: 600;
    color: $slate-gray-800;
    background-color: $dark-gray-800;
    padding: 0 1rem;
    height: 50px;
    line-height: 50px;
  }
}

//Mobile
.pf-m-dashboard {
  .my-m-col {
    background: $dark-gray-700;
    box-shadow: $slate-black-shadow;
    padding: 0 1rem;
    .mw-m-balance {
      text-align: center;
      padding: 1rem 0;
      p {
        font-size: $text-16;
      }
      .number {
        color: $slate-gray-800;
        font-size: $text-20;
      }
      .interest-container {
        display: flex;
        margin-top: 1rem;
        align-items: center;
        p {
          width: 50%;
          color: $slate-gray-800;
        }
        .number {
          color: $green-500;
          span {
            color: $slate-gray-800;
            font-size: $text-14;
          }
        }
      }
    }
    .coin-earning {
      border-top: 1px solid $slate-gray-300;
      padding: 1rem;
      .number {
        font-size: $text-18;
      }
    }
  }
  .my-m-btm-col {
    display: flex;
    justify-content: space-between;
    gap: .5rem;
    padding: .5rem 1rem;
    .my-m-btm-link {
      border-radius: rem(10px);
      height: rem(55px);
      width: 100%;
      background: $dark-gray-700;
      border: rem(2px) solid $dark-gray-600;
      color: $slate-gray-800;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      // &:first-child {
      //   border-right: 1px solid $slate-gray-300;
      // }
      img {
        width: 2rem;
        height: auto;
      }
    }
  }
  .fd-col {
    height: 100%;
    .bank-list-group {
      display: block;
      height: 100%;
    }
  }
}

.mobile-view {
  .bank-list-group {
    .pd-table {
      thead {
        display: none;
      }
    }
  }
}

.my-bd-m-col {
  display: flex;
  align-items: center;
  .my-bd-m-acc {
    font-size: $text-14;
    text-align: left;
  }
}
