.sponsor {
  .body-one {
    position: relative;
    margin-bottom: 4rem;
    .spnsor-bg-one {
      width: 100%;
      height: auto;
    }
    .sponsor-one-content {
      width: 90%;
      position: absolute;
      top: 16%;
      left: 50%;
      transform: translateX(-50%);
    }
    .spnsor-slick {
      .slick-container {
        margin-bottom: 4rem;
        position: relative;
        .slick-track {
          padding: 4rem 0;
          .slick-slide {
            transition: all 0.3s ease-in-out;
            img {
              width: 100%;
              height: auto;
            }
            &:not(.slick-center) {
              opacity: 0.5;
            }
          }
          .slick-center {
            position: relative;
            z-index: 10;
            opacity: 1;
            transform: scale(1.25);
          }
        }
      }
      .slick-dots {
        bottom: rem(-40px);
      }
    }
    h4 {
      text-align: center;
    }
  }
  .spnsor-title {
    position: absolute;
    top: 1%;
    left: 50%;
    transform: translateX(-50%);
    font-size: $text-48;
    color: white;
    font-weight: 700;
    margin: 0;
    &.title-two {
      margin-top: -8rem;
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    .slick-arrow-btn {
      cursor: pointer;
      pointer-events: visible;
      opacity: 0.25;
      background-color: $slate-gray-500;
      transition: all 0.3s ease-in-out;
      width: rem(55px);
      height: rem(120px);
      &:hover {
        background-color: $slate-gray-500 !important;
      }
      &.next {
        border-top-right-radius: rem(10px);
        border-bottom-right-radius: rem(10px);
      }
      &.prev {
        border-top-left-radius: rem(10px);
        border-bottom-left-radius: rem(10px);
      }
      &:hover {
        background-color: $blue-500;
        opacity: 100;
      }
      svg {
        color: $slate-gray-100;
        margin: auto;
        display: block;
        height: 100%;
        width: rem(60px);
      }
    }
  }

  .body-two {
    position: relative;
    .spnsor-bg-two {
      width: 100%;
      height: auto;
    }
    .sponsor-two-content {
      width: 90%;
      position: absolute;
      top: 16%;
      left: 50%;
      transform: translateX(-50%);
      .spnsor-slick {
        .slick-container {
          .slick-dots {
            bottom: -2rem;
          }
        }
        .slick-track {
          padding: 1.5rem 0;
        }
        // .slick-arrow {
        //   top: unset;
        //   bottom: 3rem;
        //   transform: unset;
        // }
        .center {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          p {
            color: $blue-600;
            font-size: $text-18;
            text-align: center;
          }
        }
        .slick-current {
          transform: translateY(-12px);
          opacity: unset !important;
        }
        .slick-slide {
          opacity: 0.5;
        }
      }
    }
    .actor-cont {
      .col-8 {
        height: rem(336px);
        overflow: auto;
      }
      p {
        font-size: $text-18;
        margin-bottom: 0.5rem !important;
        line-height: 1.7;
      }
      label {
        font-size: $text-18;
        color: black;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &.mobile-view {
    .banner-col {
      height: rem(200px);
      img {
        height: rem(200px) !important;
      }
    }

    .body-one {
      .sponsor-one-content {
        .spnsor-slick {
          .slick-container {
            margin-bottom: 0.5rem;
            .slick-track {
              padding: 1rem 0;
            }
          }
        }
        .slick-dots {
          bottom: -0.75rem !important;
          li {
            button {
              width: 0.5rem;
              height: 0.5rem;
              &::before {
                width: 0.3rem;
                height: 0.3rem;
              }
            }
          }
        }
        p {
          font-size: $text-12;
          text-align: center;
          height: 1.25rem;
          overflow: auto;
          scrollbar-width: none;
          &::-webkit-scrollbar{
            display: none;
          }
        }
      }
    }
    .slick-arrow {
      .slick-arrow-btn {
        width: rem(25px);
        height: rem(60px);
        &:hover {
          background-color: $slate-gray-500 !important;
        }
        &.next {
          border-top-right-radius: rem(10px);
          border-bottom-right-radius: rem(10px);
        }
        &.prev {
          border-top-left-radius: rem(10px);
          border-bottom-left-radius: rem(10px);
        }
        &:hover {
          background-color: $blue-500;
          opacity: 100;
        }
        svg {
          color: $slate-gray-100;
          margin: auto;
          display: block;
          height: 100%;
          width: rem(25px);
        }
      }
    }
    .spnsor-title {
      margin-top: -2.2rem;
      font-size: $text-14;
      &.title-two {
        margin-top: -1.9rem;
      }
    }
    .body-two {
      .sponsor-two-content {
        top: 10%;
      }
      .actor-cont {
        p {
          font-size: $text-10;
          margin-bottom: 0rem !important;
          line-height: 1.8;
        }
        label {
          font-size: $text-10;
          color: black;
        }
        img {
          height: 100%;
          object-fit: cover;
        }
        .col-7 {
          height: 10rem;
          overflow: auto;
        }
      }
      .spnsor-slick {
        .slick-slider {
          .slick-track {
            padding: 0 !important;
          }
        }
        .center {
          p {
            font-size: $text-10 !important;
            text-align: center;
          }
          img {
            width: rem(50px);
            height: rem(50px);
          }
        }
      }
      .slider-two {
        margin: 0rem;
        padding: 1rem 0 !important;
        .slick-track {
          width: 100% !important;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          -webkit-transform: none !important;
          transform: none !important;
          .slick-current {
            transform: unset;
          }
          .slick-slide {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
