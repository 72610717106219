.col-4.col-5ths {
  @include min-lg {
    .logo-cont.w-border {
      height: 145px !important;
    }
  }
}
.col-4 {
  @include min-lg {
    .logo-cont.w-border {
      height: 175px;
    }
  }
}
.logo-cont {
  position: relative;
  overflow: hidden;
  border-bottom: 0;
  border-right:rem(1px) solid $blue-500;
  border-left:rem(1px) solid $blue-500;
  border-top:rem(1px) solid $blue-500;
  span {
    width: 100%;
  }
  &.w-box {
    border-radius: 0.75rem !important;
    cursor: pointer;
    box-shadow: 0 4px 12px 0 rgba($gray-400, 0.4);
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding-top: 100% !important;
    position: relative;
    &:hover {
      transform: scale(1.075);
    }
  }
  
  &:not(.w-border) {
    padding-top: 65%; /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */
    // border: 1px solid $slate-gray-200;
    border-bottom: 0;
    border-radius: 0.5rem 0.5rem 0 0;
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  
  &.square-box { // if the picture is square
    &:not(.w-border) {
      padding-top: 100%;
    }
  }

  &.w-border {
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 10px solid;
    border-image-slice: 1;
    border-width: 10px;
    border-image-source: linear-gradient(to right, $blue-700, $blue-400);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-loading-skeleton {
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
  .play-now,
  .demo {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 15px;
    min-width: 90px;
    text-align: center;
    font-size: $text-12;
    font-weight: normal;
    transition: all 0.3s ease-in-out;
  }
  .play-now {
    color: $dark-gray-800;
    background: linear-gradient(to bottom, $blue-500, $blue-600);
    margin-bottom: 5px;
    &:hover {
      box-shadow: 0 0 0 0.25rem rgba($gray-100, 0.25);
    }
  }
  .demo {
    color: $blue-500;
    // background: linear-gradient(to bottom, white, $gray-100);
    border: rem(1px) solid $blue-500;
    &:hover {
      box-shadow: 0 0 0 0.25rem rgba($gray-100, 0.25);
    }
  }

  h6 {
    font-size: $text-16;
    font-weight: $font-semibold;
    text-transform: uppercase;
    color: #fff;
  }
  &:hover .overlay,
  &:focus .overlay,
  &:active .overlay {
    opacity: 1;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba($dark-gray-700, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: unset;
  }

  .fav {
    width: rem(24px);
    height: rem(22px) !important;
    left: 85% !important;
    top: 4% !important;
    z-index: 91;
    cursor: pointer;
  }
}
.title-cont {
  background: $dark-gray-800;
  border-right:1px solid $blue-500;
  border-left:1px solid $blue-500;
  border-bottom:1px solid $blue-500;
  min-height: 48px;
  border-radius: 0 0 0.5rem 0.5rem;
  h6 {
    font-weight: $font-medium;
    color: $gray-600;
    font-size: rem(13px);
  }
  img,
  svg {
    width: 40px;
    height: 25px;
    object-fit: contain;
    padding-left: 7.5px;
  }
  .rtp{
    span {
      color: $green-500;
      padding-left: .25rem;
    }
  }
}
