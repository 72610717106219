.gauge-container {
  transform: translateX(-50%);
  left: 50%;
  width: 164px;
  height: 150px;
  display: block;
  padding: 10px;
  top: 0%;
}
.gauge-container > .gauge .dial {
  stroke: #eee;
  stroke-width: 0;
  fill: rgba(0, 0, 0, 0);
}
.gauge-container > .gauge .value {
  stroke: $blue-600;
  stroke-width: 4;
  fill: rgba(0, 0, 0, 0);
}
.gauge-container > .gauge .value-text {
  fill: $blue-500;
  
  margin-top: 10px;
  font-size: $text-48;
  font-weight: $font-medium;
  line-height: 1;
  transform: translate3d(2%, 5%, 0);
}
