.pf-dashboard {
  position: relative;
  z-index: 1;
  margin-top: 2rem;
  .x-container {
    display: flex;
    margin-top: 7rem !important;
    & > .row,
    .pf-fade-col > .row {
      width: 100%;
      & > * {
        display: flex;
        flex-direction: column;
        & > :not(:last-child) {
          margin-bottom: 1rem;
        }
      }
      & > .col-12 > *:nth-child(n + 2) {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      & > .col-3 {
        & > :not(:nth-child(n + 3)) {
          height: 50%;
          .pd-content {
            height: 100%;
          }
        }
      }
    }
    .pf-fade-col {
      width: 100%;
      .row {
        height: 100%;
        .col-12 {
          height: 100%;
        }
      }
    }
  }
}

.ps-col {
  max-width: 220px;
  width: 100%;
  margin-right: 1rem;
  border-radius: 1.5rem;
  background: $dark-gray-700;
  border: rem(2px) solid $dark-gray-600;
  // box-shadow: $slate-gray-card-shadow;
  height: 100%;
  .ps-tp-panel {
    padding: 1rem;
    .ps-user-details {
      display: grid;
      justify-content: center;
      gap: 0.25rem;
      text-align: center;
      .ps-user-profile {
        position: relative;
        display: flex;
        cursor: pointer;
        width: 80px;
        height: 80px;
        margin: auto;
        p {
          text-align: center;
        }
        img {
          margin: 0 auto;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
          overflow: hidden;
        }
        .overlay {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 100%;
          width: 100%;
          transition: 0.5s ease;
          background-color: rgba($slate-gray-800, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-weight: 500;
          border-radius: 50%;
          img {
            width: 30px;
            height: 30px;
          }
        }
        &:hover {
          .overlay {
            opacity: 1;
          }
        }
      }

      .ps-username {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        p {
          max-width: rem(145px);
          text-align: center;
          overflow-wrap: break-word;
          line-height: 1.25;
          color: $slate-gray-800;
        }
        span {
          &.vip-icon {
            margin-left: 0.5rem;
            // background: $slate-gray-400;
            background-size: 55px;
          }
        }
      }

      p {
        color: $gray-50;
      }
    }
    .navbar-nav {
      flex-direction: row;
      .nav-item {
        flex: 1;
        .nav-link {
          display: block;
          text-align: center;
          color: $slate-gray-500;
          @include no-focus-hover($slate-gray-400);
          &.active {
            color: $blue-500;
          }
          img {
            display: block;
            max-width: 36px;
            margin: 0 auto .75rem auto;
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .ps-bt-panel {
    padding: 0 .75rem 1rem .75rem;
    .navbar-nav {
      .nav-item {
        .nav-link {
          position: relative;
          display: flex;
          align-items: center;
          transition: all 0.3s ease-in-out;
          padding: 0.75rem;
          .badge {
            position: absolute;
            right: 2rem;
            background-image: unset!important;
            border: solid rem(1px) $red-500;
            color:  $red-500;
          }
          &:hover > p {
            color: $blue-500;
          }
          &.active {
            // border-left: 4px solid $blue-500;
            background-color: $dark-gray-800;
            // margin: 0rem 1rem;
            border-radius: 0.5rem;
            position: relative;
            p {
              color: $blue-500;
            }
            &::after{
              content: "";
              position: absolute;
              left:0%;
              height: 3.5rem;
              width: 1rem;
              background-image: url("../../../../images/profile/nav-link-active.svg");
              background-position: center;
              background-size: cover;
            }
          }
          img {
            max-width: 25px;
            margin: 0 auto;
            width: 100%;
            height: auto;
          }
          p {
            font-size: $text-12;
            color: $slate-gray-800;
            width: 75%;
          }
        }
      }
      hr {
        margin: 1rem;
      }
    }
  }
}

.ps-upgrade-acc {
  text-align: center;
  .upgrade-acc-container {
    width: 100%;
    display: flex;
    align-items: center;
    background: $blue-100;
    padding: 0.5rem;
    border-radius: rem(5px);
    color: $slate-gray-500;
    font-weight: $font-semibold;
    font-size: $text-12;
    cursor: pointer;
    .upgrade-acc-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      margin-right: 1rem;
      svg {
        width: rem(24px);
        height: rem(24px);
        background: $slate-gray-200;
        border-radius: 1rem;
        padding: 0.25rem;
      }
    }
    &.active {
      color: $blue-600;
      .upgrade-acc-icon {
        svg {
          background: $blue-600;
        }
      }
    }
  }
  .km-btn {
    font-size: $text-12;
    color: $blue-600;
    text-decoration: underline;
    cursor: pointer;
  }
  &.mobile {
    .upgrade-acc-container {
      width: 6.5rem;
      margin-right: 1rem;
      justify-content: space-around;
      .upgrade-acc-icon {
        margin: 0;
      }
      p {
        font-size: $text-10;
        line-height: 1.2;
      }
    }
  }
}

.nav-link.invalid {
  pointer-events: none;
  opacity: 0.5;
  p {
    line-height: $leading-tight;
  }
  small {
    font-size: $text-12;
  }
}

.pd-card-col {
  position: relative;
  h4,
  h6 {
    margin-bottom: 1rem;
  }
  .pd-header-more {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    h6 {
      margin-bottom: unset;
      margin-right: 0.5rem;
    }
    a {
      font-size: $text-12;
      line-height: 1.2;
      white-space: nowrap;
      @include no-focus-hover($slate-gray-300);
    }
  }
  .pd-content {
    position: relative;
    .acc-w-border{
      background: $dark-gray-800;
      border: rem(2px) solid $dark-gray-600;
      border-radius: 0.5rem;
      padding: 0.25rem 0rem;
    }
    &.bordered {
      padding: 1rem;
      border-radius: 0.5rem;
      background: $dark-gray-800;
      border: rem(2px) solid $dark-gray-600;
    }
    img {
      &.verify-icon {
        width: 18px;
      }
    }
  }
  .pd-tbs-col {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid $slate-gray-500;
    border-radius: 0.25rem;
    img {
      margin-right: 7px;
    }
    p {
      color: $slate-gray-800;
      font-size: $text-12;
      font-weight: $font-medium;
    }
  }
  .pd-currency-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
    img {
      width: 45px;
      height: 45px;
      margin-right: 1rem;
    }
    p {
      display: inline-block;
      font-size: $text-16;
      b {
        color: $slate-gray-800;

        &.tx-green {
          color: $green-500;
        }
      }
    }
    a {
      @include no-focus-hover($slate-gray-300);
    }
    &.pi {
      justify-content: unset;
      span.mx-4 {
        display: inline-block;
        height: 30px;
        width: 0.5px;
        background-color: $slate-gray-500;
      }
    }
  }
}

.verified-col {
  width: 55%;
  display: flex;
  align-items: center;
  word-break: break-all;
  text-transform: capitalize;
  .form-control {
    max-width: max-content;
  }
  .verified-label {
    margin-left: rem(7px);
    // font-style: italic;
    color: $slate-gray-800;
  }
}

.input-group {
  &.indonesia {
    .form-control {
      padding-right: 3.5rem !important;
    }
    &::after {
      content: "(‘000)";
      position: absolute;
      z-index: 5;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      color: $slate-gray-200;
    }
  }
}

.no-records {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  label {
    display: block;
    font-weight: $font-medium;
  }
  label,
  p {
    font-size: $text-12;
  }
  img {
    max-width: 15rem;
    width: 100%;
    height: auto;
  }
  .btn-gradient-blue {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
  }
  a:not(.btn-gradient-blue) {
    @include no-focus-hover($slate-gray-300);
  }
}
.pf-m-dashboard {
  .no-records {
    padding: 0 1rem;
    .btn-gradient-blue {
      width: 100%;
    }
  }
}

.notify-num {
  position: absolute;
  right: -2.5%;
  top: -7.5%;
  display: inline-block;
  width: rem(25px);
  height: rem(17px);
  border-radius: 0.5rem;
  color: $red-400;
  // background-image: linear-gradient(0deg, $red-500, #f7778c);
  border: 2px solid $red-400;
  font-size: $text-10;
  font-weight: $font-medium;
  line-height: rem(17px);
}

.notify-red-dot {
  position: absolute;
  top: 0;
  right: 5%;
  display: inline-block;
  width: rem(12px);
  height: rem(12px);
  border-radius: 50%;
  background-color: $red-400;
  // border: 2px solid white;
}

// Mobile Profile Dashboard
.pf-m-dashboard {
  // display: flex;
  // flex-direction: column;
  // height: 100%;
  // overflow: auto;
  &.on-top {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
  height: var(--app-height);
    width: 100vw;
    overflow-y: auto;
    background-color :$dark-gray-800;
    &.mall {
      z-index: 999;
    }
  }
  .col-form-label {
    color: $slate-gray-500;
    padding: 0rem 0.75rem;
    margin-bottom: 1rem;
  }
  .input-group-col {
    background: $dark-gray-700;
    padding: 0.25rem 0.75rem;
    // margin: -.25rem;
    .form-control {
      // min-height: unset;
      border: 0;
    }
    .form-check-group {
      margin: -0.25rem;
    }
    img {
      // width: rem(25px);
      height: rem(18px);
      margin: auto rem(7px) auto 0;
    }
    .form-check.form-check-inline {
      margin: 0;
      padding: 0.25rem;
      &.four-check {
        width: 25%;
        // width: 33%;
        label {
          font-size: $text-12;
          flex-direction: column;
          height: 5rem;
          padding: .2rem;
          overflow: hidden;
          img {
            margin-bottom: .25rem;
          }
          p{
            text-transform: capitalize;
            margin-bottom: .25rem !important;
          }
        }
      }
      &.five-check {
        width: 20%;
      }
      .form-check-label {
        max-width: unset;
        min-width: unset;
      }
    }
    .input-group {
      // min-height: unset;
      &.left {
        .input-group-text {
          padding-left: 0;
        }
        .form-control {
          padding-left: 0;
          padding-right: 0;
        }
      }
      &.right {
        .input-group-text {
          padding-right: 0;
        }
        .form-control {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .input-group-text {
        border: 0;
        svg {
          font-size: 1.5rem;
        }
      }
    }
    .form-control,
    .form-select {
      &:focus,
      &:focus-within,
      .is-valid:focus,
      &:valid:focus,
      .is-invalid:focus,
      &:invalid:focus {
        box-shadow: none;
      }
      &[readOnly] {
        background-color: transparent;
        & + .input-group-text {
          background-color: transparent;
        }
      }
    }
    .bank-list-group {
      > *:not(.select-bank-opts) {
        .form-check-label {
          &:not(.wdrawal-add-bank) {
            min-height: rem(50px);
            > * {
              flex: unset;
              &:nth-child(1) {
                display: flex;
                align-items: center;
                padding-right: 1rem;
              }
            }
          }
          .list-details {
            max-width: 10rem;
            p {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
  .pd-m-card-col {
    // min-height: 65vh;
    // overflow: hidden;
    margin: 1rem 0;
    background: $dark-gray-800;
    box-shadow: $slate-gray-card-shadow;
  }
  &.dnw-section {
    .col-form-label {
      color: $slate-gray-800;
      margin-bottom: 0.25rem;
      font-weight: 500;
    }
    .pd-form-m-col {
      background: $dark-gray-700;
      padding-bottom: 2rem;
      .input-group-col {
        .form-control {
        background-color: $blue-100;
        border-radius: 0.25rem;
      }
        .btn-gradient-blue {
          height: 2.5rem;
          border-radius: 0.25rem;
        }
      }
    }
    .input-group-col {
      background: $dark-gray-700;
      // padding: 0.25rem 1rem;
      align-items: center;
      .form-check.form-check-inline {
        padding: 0.2rem;
      }
      img {
        height: 1.75rem;
        margin: unset;
        width: unset;
      }
      .input-group {
        // min-height: unset;
        &.left {
          .input-group-text {
            padding-left: 1rem;
            background-color: $dark-gray-800;
            border: rem(2px) solid $dark-gray-600;
            color: $slate-gray-800;
            border-radius: 0.25rem;
          }
          .form-control {
            color: $slate-gray-800;
            border: rem(2px) solid $dark-gray-600;
            background-color:$dark-gray-800;
            padding-left: 1rem;
            padding-right: 1rem;
            border-radius: 0.25rem;
          }
        }
        &.right {
          color: $slate-gray-800;
          border: rem(2px) solid $dark-gray-600;
          background-color:$dark-gray-800;
          border-radius: 0.5rem;
          .input-group-text {
            padding-right: 0.75rem;
          }
          .form-control {
            color: $slate-gray-800;
            background: $dark-gray-800;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
        .input-group-text {
          border: 0;
          svg {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  &.remain-w {
    .input-group-col {
      img {
        height: 1.75rem;
        margin: auto rem(7px) auto rem(7px);
      }
    }
  }
}

.pd-form-m-col {
  display: grid;
  gap: 1rem;
  .input-group-col {
    .btn-gradient-blue {
      min-width: 4rem;
      height: max-content;
      border-radius: 1rem;
    }
  }
}

.pf-dashboard,
.pf-m-dashboard {
  small {
    span {
      text-decoration: underline;
      color: $blue-500;
      cursor: pointer;
    }
  }
}