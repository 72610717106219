.x-container {
  max-width: 1200px !important;
  min-width: 1200px;
  margin: 5rem auto;
  padding: 0 0.75rem;
}

.x-container-no-m {
  max-width: 1200px !important;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0 0.75rem;
}

.m-container {
  padding: 0 1rem;
}

.desktop-view {
  min-width: 1200px;
}

// .master-container {
//   background-image: url("../../images/home/christmas/master-bg.jpg");
//   background-attachment: fixed;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }

.mobile-view {
  font-size: 3.75vw;
  overflow: hidden;
  .master-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: var(--app-height);
    // overflow: hidden;
    .master-content {
      height: 100%;
      overflow: auto;
    }
  }
}

body {
  background-color: $black-900;
}

// .mobile-view {
//   body {
//     background: $slate-gray-100;
//     &.home {
//      background: linear-gradient(
//       to bottom,
//       white,
//       $slate-gray-100
//     ) !important;
//     }
//   }
// }

.picker-inner {
  touch-action: none;
}

p {
  margin-bottom: 0 !important;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

.mobile-view {
  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }
}

/* Track */

::-webkit-scrollbar-track {
  background: $black-600;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: $blue-500;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: $blue-500;
}

.col-5ths {
  flex: 0 0 auto;
  width: 20%;
  // margin-top: 1.5rem;
}

a {
  color: $blue-500;
  text-decoration: none;
}

.search-bar {
  display: flex;
  align-items: center;
  width: rem(250px);
  position: relative;
  color: $slate-gray-200;
  // margin-bottom: 1rem;
  img,
  svg {
    position: absolute;
    right: 8%;
    color: $slate-gray-300;
  }
  img + input {
    padding-left: rem(40px);
  }
  .form-control {
    border: 1px solid $gray-50;
    border-radius: 0.5rem;
    background-color: transparent;
    &::placeholder {
      color: $slate-gray-300;
    }
  }
}

.react-loading-skeleton {
  background-color: $dark-gray-600 !important;
  background-image: linear-gradient(
    90deg,
    $dark-gray-600,
    $dark-gray-700,
    $dark-gray-600,
  ) !important;
}

.card-shadow {
  border-radius: 1.5rem;
  box-shadow: 0 4px 12px 0 rgba($slate-gray-500, 0.25);
  display: block;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}

::-webkit-scrollbar {
  width: 10px;
}
.mobile-view {
  ::-webkit-scrollbar {
    width: 5px;
  }
}

.flip-number {
  position: absolute;
  z-index: 10;
  top: 67%;
  left: 50%;
  transform: translate(-50%);
  section > * {
    margin: 0 0.25rem;
    background-image: url("../../images/number-bg.webp");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  img {
    position: absolute;
    width: 127%;
    height: auto;
    bottom: -47%;
    left: -14%;



  }
  span {
    font-size: 40px !important;
    z-index: 1 !important;
  }
}

.mobile-view {
  .flip-number {
    img {
      width: 127%;
      bottom: -88%;
      left: -14%;
    }
  }
}

.fade-in-right {
  animation: fadein-right 1s;
}

.fade-out-right {
  animation: fadeout-right 1s;
}

.fade-in-left {
  animation: fadein-left 1s;
}

.fade-out-left {
  animation: fadeout-left 1s;
}

hr {
  border: none;
  height: 1px;
  opacity: 1;
  background-color: $slate-gray-200;
}

.game-m-nav {
  .game-nav-btn {
    position: relative;
    width: 100%;
    height: rem(40px);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(6px);
    line-height: $leading-tight;
    background-image: url("../../images/btn-white-m.png");
    .active-nav-img {
      display: none;
    }
    img {
      width: 30%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1 !important;
    }
    p {
      // width: 70%;
      height: auto;
      font-size: $text-12;
      text-align: center;
      color: $slate-gray-500;
    }
    .event-btn-deco{
      display: none;
    }

    &.active {
      background-image: url("../../images/btn-blue-m.png");
      p {
        color: white;
      }
      .nav-img {
        display: none;
      }
      .active-nav-img {
        animation: zonIn 0.25s;
        display: block;
      }
      &.event {
        background-image: url("../../images/home/raya/btn-active.png");
        .event-btn-deco{
          position: absolute;
          top: 0;
          left: -0.875rem;
          display: block;
        }
      }
    }
  }
}

.game-m-nav-v2 {
  display: flex;
  flex-direction: column;
  gap:0.5rem;
  overflow-y: auto;
  padding-bottom: 1.5rem;
  &::-webkit-scrollbar {
    display: none;
  }
  .game-nav-btn {
    position: relative;
    width: rem(60px);
    height: rem(60px);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    padding: rem(6px);
    line-height: $leading-tight;
    background-image: url("../../images/btn-black-m.webp");
    flex-direction: column;
    .active-nav-img {
      display: none;
    }
    img {
      width: 70%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1 !important;
    }
    p {
      height: auto;
      font-size: $text-12;
      text-align: center;
      color: $slate-gray-500;
    }
    .event-btn-deco{
      display: none;
    }

    &.active {
      background-image: url("../../images/btn-blue-m.webp");
      p {
        color: white;
      }
      .nav-img {
        display: none;
      }
      .active-nav-img {
        animation: zonIn 0.25s;
        display: block;
      }
      &.event {
        background-image: url("../../images/home/raya/btn-active.png");
        .event-btn-deco{
          position: absolute;
          top: 0;
          left: -0.875rem;
          display: block;
        }
      }
    }
  }
}

.sb-login-register{
  // width: 9rem;
  // height: 2.5rem;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // border-radius: 0.5rem!important;
  // font-size: $text-12;
  // color: $slate-gray-400;
  border-radius: 0.75rem!important;
  background: linear-gradient(#87E3F1, $blue-600)!important;
}

.react-loading {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(black, 0.75);
  position: fixed;
  top: 0;
  z-index: 99999;
  pointer-events: all;
  overscroll-behavior: contain;
  > div {
    position: absolute;
    text-align: center;
    .loading-img {
      width: 10rem;
      height: auto;
      margin-bottom: rem(-25px);
      opacity: 0.75;
    }
  }
}

.react-bootstrap-table table {
  // min-width: 576px;
  table-layout: auto;
}

.pf-m-dashboard {
  .css-1gzxeik {
    .react-bootstrap-table + .no-records {
      height: unset !important;
      min-height: 50vh;
    }
  }
}

.css-1gzxeik {
  display: flex;
  flex-direction: column;
  height: 100%;
  .react-bootstrap-table + .no-records {
    height: calc(100% - 4.125rem) !important;
  }
}

// mobile user profile info
.pi-m-info {
  display: flex;
  align-items: center;
  .user-profile-container {
    width: 30%;
    height: auto;
    .user-profile-img {
      width: 3.75rem;
      height: 3.75rem;
    }
    .vip-icon {
      background: linear-gradient(to right, $orange-100, $orange-500);
      right: 4%;
    }
  }
  .user-info {
    width: 70%;
    color: $gray-200;
    .us-name,
    .us-join {
      word-break: break-all;
    }
    .us-join {
      color: white;
    }
  }
}

// mobile sub-header
.m-subHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 2rem 0 1rem .5rem;
  color: $slate-gray-800;
  // background: $dark-gray-700;
  .sub-link {
    display: flex;
    align-items: center;
    width: 25%;
    color: $gray-200;
    @include no-focus-hover($slate-gray-800);
    p {
      margin-left: auto;
      margin-right: rem(7px);
    }
    &:only-child {
      margin-left: auto;
    }
  }
  h6 {
    width: 50%;
    text-align: center;
    font-size: $text-16;
    font-weight: $font-regular;
    margin-bottom: 0;
  }
  svg {
    font-size: $text-36;
    color: $slate-gray-500;
  }
}

.number {
  font-weight: $font-medium;
  word-break: break-all;
}

//vip icon
.vip-icon {
  width: rem(62px);
  min-width: rem(34px);
  height: rem(20px);
  // background: linear-gradient(to right, $orange-100, $orange-500);
  // background: url("../../images/vip/vip-badge.webp");
  background-size: 125px;
  background-repeat: no-repeat;
  color: $slate-gray-100;
  text-align: center;
  font-weight: $font-bold;
  font-size: $text-10;
  font-style: italic;
  line-height: 1.25rem;
  border-radius: rem(5px);
  z-index: 1;  
  img{
    width: 100%;
    height: auto;
  }
}

@keyframes shine {
  0% {
    opacity: 1;
    top: -75%;
    left: -125%;
  }
  100% {
    opacity: 1;
    top: -25%;
    left: 125%;
  }
}

//Event date
.event-date {
  font-size: $text-18 !important;
  color: $slate-gray-500 !important;
  margin-top: 1rem;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    $slate-gray-200,
    rgba(255, 255, 255, 0)
  );
}

//edit icon
.edit-icon {
  width: rem(30px) !important;
  height: rem(30px) !important;
  position: absolute;
  bottom: 0;
  right: 4%;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: $blue-hover-shadow;
  }
}

//display mobile x33 coin and today earning
.coin-earning {
  display: flex;
  background: $dark-gray-700;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  .coin {
    width: 50%;
    display: flex;
    align-items: center;
    border-right: 1px solid $slate-gray-500;
    img {
      width: rem(40px);
      height: rem(40px);
    }
    div {
      text-align: start;
      line-height: $leading-tight;
      margin-left: 0.5rem;
      font-size: $text-12;
    }
  }
  .earning {
    width: 50%;
    font-size: $text-12;
    text-align: center;
    .number {
      color: $green-500 !important;
    }
  }
}

.my-learn-more {
  font-size: $text-12;
  color: $blue-500;
  cursor: pointer;
}

//3th party account auth
.third-party-list {
  margin-top: 1rem;
  list-style-type: none;
  padding: 0;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background: $dark-gray-800;
    border: rem(2px) solid $dark-gray-600;
    border-radius: 0.25rem;
    div {
      display: flex;
      align-items: center;
      color: $slate-gray-800;
      img {
        width: rem(26px);
        height: rem(26px);
        margin-right: 1rem;
      }
      small {
        font-size: $text-10;
        margin-left: 1rem;
        color: $slate-gray-500;
      }
    }
    .btn {
      min-width: 3.25rem;
      height: 1.5rem;
      border-radius: 1rem;
      font-size: $text-12;
      padding: 0 0.5rem;
    }
  }
}

//tooltip
.tooltip {
  .tooltip-arrow {
    &::before {
      border-left-color: white;
    }
  }

  .tooltip-inner {
    color: $red-500;
    background-image: linear-gradient(
      to bottom,
      white,
      $slate-gray-100
    ) !important;
    box-shadow: $slate-gray-card-shadow;
    // border: 1px solid $red-500;
  }
}
.custom-tooltip {
  background: $blue-500 !important;
  font-size: $text-12 !important;
  &.place-top {
    &::after {
      border-top-color: $blue-500 !important;
    }
  }
  &.place-left {
    &::after {
      border-left-color: $blue-500 !important;
    }
  }
}
.mobile-view {
  .custom-tooltip {
    font-size: $text-10 !important;
  }
}

//message whatsapp setting
.msg-whatsapp {
  display: grid;
  padding: 0 0.5rem;
  background-color: $dark-gray-700;
  color: $slate-gray-800;
  // border: rem(1px) solid rgba($dark-gray-600, 0.5);
  border-radius: rem(5px);
  svg {
    font-size: $text-20;
    color: $green-500;
  }
  label,
  input {
    color: $slate-gray-500;
    cursor: pointer;
    margin: 0;
    font-size: $text-12;
  }
  span {
    width: 100%;
  }
}

.msg-whatsapp, .msg-m-whatsapp {
  .form-check-input {
    background-color: $dark-gray-700;
    border-radius: 99px;
    &:checked {     
      background-image: url("../../images/icons/mobile/checked-blue.webp");
      border: unset;
    }
  }
}

.msg-m-whatsapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin-top: 1rem;
  font-size: $text-12;
  svg {
    color: $green-500;
    font-size: $text-20;
  }
  input {
    margin: 0;
  }
  p {
    color: $slate-gray-800;
  }
  span {
    width: 12rem;
  }
  .whatsapp-title {
    padding-right: 0.75rem;
    border-right: rem(1px) solid rgba($slate-gray-200, 0.5);
  }
}

.note-text {
  // color: white !important;
  // background-color: $dark-gray-800;
  padding: 0 0.5rem;
  // border-radius: rem(4px);
  // font-weight: $font-medium;
  // text-transform: uppercase;
  img{
    width: 1.5rem;
    height: 1.5rem;
  }

}

//interest T&C
.interest-tnc {
  width: max-content;
  min-width: 10rem;
  display: flex;
  align-items: center;
  border: rem(1px) solid $slate-gray-500;
  border-radius: 1rem;
  font-size: $text-12;
  padding: rem(2px) 0.25rem;
  line-height: 1;
  color: $slate-gray-500;
  font-weight: $font-medium;
  // background: $blue-100;
  margin-right: 0.5rem;
  cursor: pointer;
  svg {
    font-size: $text-14;
    margin-right: 0.25rem !important;
  }
}

//react image modal image size
.__react_modal_image__medium_img {
  min-width: 22rem;
}
.view-image {
  color: $blue-500;
  cursor: pointer;
}

//crypto deposit payment status form
.cypto-deposit-modal {
  max-width: 480px !important;
  .crypto-deposit {
    padding: 1rem;
    background-image: unset!important;
    background-color: $blue-100;
    .modal-header {
      position: relative;
      align-items: flex-start;
      border-bottom: rem(1px) solid $slate-gray-200 !important;
      padding: 0;
      svg {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .cd-top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $slate-gray-800;
      img {
        width: 100%;
        max-width: rem(40px);
        height: auto;
      }
      .cd-status {
        font-weight: $font-semibold;
        font-size: $text-16;
        text-transform: capitalize;
      }
      .count-down {
        font-size: $text-18;
        color: $red-500;
      }
      .cd-usdt-amount {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $blue-500;
        font-size: $text-18;
        .cd-ref-no {
          margin: 0;
          svg {
            position: unset;
          }
        }
      }
    }
    .modal-body {
      padding: 0;
      .cd-details {
        .row {
          margin-bottom: 0.5rem;
          &:first-child {
            margin-top: 0.5rem;
          }
          .col-4{
            color: $slate-gray-300;
          }
          .col-8 {
            color: $slate-gray-800;
            text-align: right;
          }
        }
        .cd-qr-code {
          border-top: rem(1px) solid $slate-gray-200;
          .col-8 {
            width: auto;
            display: flex;
            justify-content: center;
            background: white;
            padding: 0.75rem;
            box-shadow: $slate-gray-card-shadow;
          }
        }
        .cd-copy{
          .form-control{
            border-left: none;
            border-right: none;
            border-color: $slate-gray-200;
            background: white;
            font-size: $text-12;
            color:$blue-600;
          }
          .text-danger{
            font-size: 0.7rem;
          }
          .left-icon{
            margin-right: -5px;
            border-color: $slate-gray-200;
            border-radius: 0.5rem;
            font-size: $text-12;
            width: 9rem;
          }
          .right-icon{
            border-left: none;
            border-color: $slate-gray-200;
            border-radius: 0.5rem;
            svg{
              color:$blue-600;
            }
          }
        }
        .cd-tnc {
          padding: 0.5rem 0;
          border-top: rem(1px) solid $slate-gray-200;
          p {
            color: $slate-gray-800;
            font-weight: $font-semibold;
          }
          ol {
            padding-left: 1rem;
            li {
              font-size: $text-12;
            }
          }
        }
      }
    }
    .cd-ref-no {
      display: flex;
      justify-content: center;
      margin-bottom: 0.5rem;
      align-items: center;
      color: $blue-500;
      .icon {
        margin: 0;
        color: $blue-500;
      }
    }
  }
}
//crypto deposit payment status form mobile view
.mobile-view {
  .cypto-deposit-modal {
    .crypto-deposit {
      .modal-header {
        padding: 1rem 1rem 0 1rem;
        .cd-top {
          padding-top: 1.5rem;
          img {
            min-width: 4rem;
            position: absolute;
            top: -18%;
            left: 50%;
            transform: translateX(-50%);
            background-color: white;
            border: 0.25rem solid white;
            border-radius: 50%;
          }
        }
        .btn-close {
          top: 1rem;
          right: 1rem;
        }
      }
      .modal-body {
        .cd-details {
          .row {
            padding: 0 1rem;
            .col-4 {
              color: $slate-gray-800;
            }
            .col-8 {
              color: $slate-gray-500;
            }
          }
          .cd-qr-code {
            .cd-ref-no {
              padding: 0 1rem;
              p {
                height: 1.875rem;
                width: 75%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: rem(1px) solid $slate-gray-300;
                border-right: 0;
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
                padding: 0 1rem;
                color: $slate-gray-800;
              }
              // input {
              //   border: rem(1px) solid $slate-gray-200;
              //   border-top-left-radius: 0.25rem;
              //   border-bottom-left-radius: 0.25rem;
              //   border-right: 0;
              //   width: 75%;
              //   padding-left: 0.25rem;
              //   text-align: left;
              //   color: $slate-gray-800;
              // }
              .icon {
                width: 25%;
                background: linear-gradient(to right, $blue-400, $blue-600);
                color: white;
                font-size: $text-12;
                font-weight: $font-semibold;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }
          }
          .cd-tnc {
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }
}
//end

//countdown timer

.countdown-timer {
  .countdown-digit-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .countdown-digit {
      display: flex;
      position: relative;
      .symbol {
        position: absolute;
        top: 0;
        right: -4%;
        font-size: $text-48;
        color: $blue-500;
      }
      .digit-container {
        background-color: transparent;
        border-radius: 10px;
        padding: 5px 10px;
        margin-bottom: 5px;
      }
    }
    &:last-child {
      .countdown-digit {
        .symbol {
          display: none;
        }
      }
    }
  }
}

//custom list
.custom-list {
  p {
    display: list-item;
    list-style: disc;
  }
}