.wc-section {
  .wc-category-cont {
    .match-date-btn {
      padding: 0rem 0.5rem;
      .lb-game {
        background-image: url("../../../images/game-white.png");
        background-size: 120% 100%;
        background-position: center;
        background-repeat: no-repeat;
        color: $slate-gray-500;
        border-radius: rem(50px);
        border: none;
        display: flex;
        font-weight: $font-medium;
        width: 100%;
        height: 4rem;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        align-items: center;
        justify-content: center;
        padding: inherit;
        cursor: pointer;
        box-shadow: $slate-gray-shadow;
        flex-direction: column;
        margin: 0.5rem 0rem;
        &.active {
          background-image: url("../../../images/game-blue.png");
          color: white;
          box-shadow: 0 4px 12px 0 rgba($blue-500, 0.25);
          .icon-grey {
            display: none;
          }
          .icon-white {
            display: block;
          }
        }

        &:hover {
          box-shadow: $slate-gray-hover-shadow;
        }
        h5 {
          margin-bottom: 0rem;
        }
        p {
          font-size: $text-12;
          .icon-grey {
            margin: auto;
            display: block;
          }

          .icon-white {
            margin: auto;
            display: none;
          }
        }
        .status {
          color: white;
          width: 4rem;
          text-align: center;
          border-radius: 1rem;
          margin-top: 0.2rem;
          &.deactive {
            background-color: $red-500;
          }
          &.active {
            background-color: $green-500;
          }
          &.pending {
            background-color: $gray-500;
          }
        }
      }
    }
    .slick-container {
      position: relative;
      width: 80%;
      margin: 0 auto;
      .slick-slider {
        padding: 0rem 4rem;
      }
      .slick-arrow {
        display: flex;
        justify-content: space-between;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 100%;
        pointer-events: none;
        .slick-arrow-btn {
          border-radius: 0.5rem;
          background-color: white;
          border: 1px solid $gray-400;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          pointer-events: auto;
          transition: all ease-in-out 0.3s;
          &:hover {
            box-shadow: $slate-gray-hover-shadow;
          }
          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }

  .wc-vs-section {
    background-color: white;
    padding: 2rem;
    text-align: center;
    border-radius: 1rem;
    box-shadow: $slate-gray-card-shadow;
    &.wc-table {
      .wc-head {
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;
        text-align: center;
      }
      .wc-body {
        width: 50%;
      }
      .wc-bg {
        position: relative;
      }
      .wc-content {
        position: absolute;
        top: 0.5rem;
        left: 0;
        height: auto;
        text-align: center;
        width: 100%;
      }
      .wc-details {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 2.8rem;
        .form-check {
          position: relative;
          margin: 0rem;
          .win-img {
            width: 4rem;
            height: 4rem;
            position: absolute;
            top: -2rem;
            right: 1.95rem;
          }
          &.enable {
            background: linear-gradient(to bottom, #b3c9fc, #eef5ff 50%);
            border-radius: 0.5rem;
          }
          .btn-check {
            &:active,
            &:focus {
              & + .form-check-label {
                box-shadow: $blue-shadow !important;
              }
            }
          }
        }
        .form-check-label {
          width: 8rem;
          height: 10rem;
          flex-direction: column;
          background-color: rgba(white, 0.3);
          border-radius: 0.5rem;
          border: unset;
          box-shadow: $blue-shadow;
          &::after {
            background: unset;
            border: rem(3px) solid $blue-600 !important;
            height: 100%;
            width: 100%;
            bottom: 0;
            right: 0;
            border-radius: 0.5rem;
          }
          img {
            width: 5rem;
            margin-bottom: 1rem;
          }
        }
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
          margin-right: unset;
        }

        .versus-body {
          display: flex;
          flex-direction: column;
          // justify-content: flex-end;
          // height: 10rem;
          .btn-gradient-blue {
            font-weight: 600;
            height: 1.5rem;
            padding: 0.3rem;
            border-radius: 1rem;
            box-shadow: $slate-gray-shadow;
            width: 100%;
            letter-spacing: 0.2rem;
          }
          .score-font {
            font-size: $text-36;
            font-weight: 600;
            letter-spacing: 0.2rem;
          }
          .aet-font {
            color: $red-500;
            margin-top: -0.5rem;
            font-weight: 600;
          }
        }
        .versus-img {
          width: 4rem;
        }
      }
      .wc-notice {
        img {
          width: 50%;
        }
        h1 {
          margin-bottom: 1rem;
        }
      }
    }
    .btn-gradient-blue {
      border: unset;
      height: 3rem;
      width: 50%;
    }
  }

  .wc-reward {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
    .wc-reward-container {
      border-radius: 1rem;
      background: linear-gradient(to bottom, #fbfcff, #eef5ff 30%);
      box-shadow: $slate-gray-card-shadow;
      color: $slate-gray-500;
      opacity: 0.8;
      text-align: center;
      padding: 0rem 0rem 1rem 0rem;
      &.col-3 {
        width: 20%;
      }
      .wc-reward-header {
        background-color: white;
        padding: 1rem;
        width: 100%;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
      &.active {
        box-shadow: 0 0px 10px 0 rgba($blue-500, 0.7);
      }
      &.inactive {
        opacity: 0.5;
      }
    }
    &.fifa-qna {
      .col-2 {
        &:last-child {
          width: 36%;
        }
      }
    }
  }

  .result-text {
    color: $gray-500;
    .orange-text {
      color: $orange-500;
    }
    .blue-text {
      color: $blue-600;
    }
  }

  .react-select {
    .react-select__control {
      height: auto;
    }
    .react-select__option {
      justify-content: center !important;
      width: 25%;
      display: inline-block;
      padding: 0rem;
      &:hover {
        background-color: unset;
      }
      img {
        width: 2rem;
        height: 2rem;
        margin-right: 0 !important;
        border: rem(1px) solid $slate-gray-200;
        border-radius: 1rem;
      }
      .form-check {
        margin: 0rem;
        input {
          display: none;
          pointer-events: unset;
        }
        .form-check-label {
          height: 5rem;
          min-width: 7rem;
          max-width: 7rem;
          flex-direction: column;
          border: unset;
          p {
            padding-top: 0.5rem;
          }
          &:hover {
            box-shadow: unset;
            border: rem(1px) solid $slate-gray-200;
            border-radius: 1rem;
            transition: unset;
          }
        }
      }
      &.react-select__option--is-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .react-select__menu-list {
      max-height: rem(300px);
      padding: 0.5rem;
    }
    .react-select__multi-value {
      img {
        width: 1.5625rem;
        height: 1.5625rem;
      }
    }
  }

  .fifa-two-body {
    display: block;
    width: 50%;
    margin: 0 auto;
    .vip-lvl-col {
      .vip-lvl-md {
        margin: 2rem 0rem;
        ul {
          li {
            span {
              right: unset;
              width: 3rem;
              background: unset;
              color: unset;
              pointer-events: none;
            }
          }
        }
      }
    }
    .btn-outline-white {
      height: 2rem;
      width: 20%;
      background: white;
      margin: 0rem 0.5rem;
    }
  }
}

.wc-modal {
  .modal-content {
    background: linear-gradient(to bottom, #b3c9fc, white 30%) !important;
    position: relative;
    .modal-body {
      padding: 3rem;
    }
  }
  .btn-gradient-blue {
    width: 60%;
  }
}

.wc-check-result {
  .modal-dialog {
    max-width: rem(1000px);
    .modal-content {
      padding: 3rem 2rem;
      .modal-body {
        background-color: white;
        box-shadow: $slate-gray-shadow;
        border-radius: 1rem;
        .row {
          display: flex;
          align-items: center;
          .col-2 {
            p {
              display: flex;
              align-items: center;
            }
            span {
              border-radius: 0.5rem;
              border: rem(1px) solid $gray-400;
              padding: 0.5rem 1rem;
            }
          }
          .col-4 {
            p {
              display: flex;
              align-items: center;
            }
            .react-select {
              width: 72%;
            }
          }
        }
        .table {
          tbody {
            tr {
              td {
                color: $slate-gray-500 !important;
                font-size: $text-16;
                border-bottom-width: 0 !important;
                text-transform: uppercase;
                img {
                  max-width: 1.5rem;
                  max-height: 1.5rem;
                  box-shadow: rem(0px) rem(2px) rem(3px) $blue-400;
                  border-radius: 1rem;
                }
              }
              &:nth-child(even) {
                td {
                  background-color: white !important;
                }
              }
              &:nth-child(odd) {
                td {
                  background-color: $slate-gray-100;
                }
              }
            }
          }
        }
      }
    }
  }
}

.fifa-tbody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  .fifa-tcontent {
    width: 8rem;
  }
  h6 {
    margin-bottom: 0rem !important;
  }
  img {
    border: rem(1px) solid $gray-400;
    margin-bottom: 0.5rem;
    border-radius: 2rem;
    width: 3rem;
    height: auto;
  }
}

.green-font {
  color: $green-500;
}

.red-font {
  color: $red-500;
}

.orange-font {
  color: $orange-500;
}

.fifa-rh {
  ul {
    list-style: none;
    padding: 0rem;
  }
  li {
    display: flex;
    align-items: flex-end;

    img {
      width: 1.5rem;
    }
    .left-head {
      width: 55%;
      padding: 0rem 1rem;
      position: relative;
      &::before {
        box-shadow: -18px 0 10px -20px rgba($slate-gray-500, 0.25) inset;
        content: " ";
        height: 100%;
        left: -20px;
        position: absolute;
        top: 0;
        width: 20px;
      }
      &::after {
        box-shadow: 18px 0 10px -20px rgba($slate-gray-500, 0.25) inset;
        content: " ";
        height: 100%;
        position: absolute;
        top: 0;
        right: -20px;
        width: 20px;
      }
      .left-content {
        padding: 0.5rem 0rem 0.5rem 0.5rem;
      }
    }
    &:first-child {
      .left-head {
        box-shadow: 0 -5px 6px 0px rgba($slate-gray-500, 0.25);
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        padding-top: 1rem;
        &::before {
          display: none !important;
        }
        &::after {
          display: none !important;
        }
      }
    }
    &:nth-child(2) {
      .left-head {
        &::before {
          height: 110%;
          top: rem(-5px);
        }
        &::after {
          height: 110%;
          top: rem(-5px);
        }
      }
    }
    &:last-child {
      .left-head {
        box-shadow: 0 5px 6px 0px rgba($slate-gray-500, 0.25);
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        padding-bottom: 1rem;
        &::before {
          height: 9%;
        }
        &::after {
          height: 9%;
        }
      }
      .right-head {
        padding-bottom: 1.5rem;
      }
    }
    &:nth-child(even) {
      .left-content {
        background-color: $slate-gray-100;
      }
    }
    &:nth-child(odd) {
      .left-content {
        background-color: white !important;
      }
    }
    .right-head {
      width: 45%;
      padding-bottom: 0.5rem;
    }
    .team-content {
      padding: 0rem 0.3rem;
    }
  }

  .rdp-his-content {
    .btn-outline-blue {
      padding: 0.5rem 1rem;
    }
  }

  :has(.show) {
    .turn-btn {
      transform: rotate(180deg);
    }
  }
}

.mobile-view {
  .wc-section {
    margin: 0rem 1rem;
    .wc-vs-section {
      border-radius: 1rem;
      padding: 0.5rem;
      .wc-head {
        flex-direction: column;
        align-items: center;
        margin: 0rem;
      }
      .versus-img {
        width: 3rem !important;
      }
      &.wc-table {
        .wc-body {
          width: 100%;
          padding: 0rem;
          .wc-content {
            top: 0.2rem;
            .wc-details {
              margin-top: 1.5rem;
              .versus-body {
                span {
                  font-size: $text-12;
                }
                .btn-gradient-blue {
                  height: 1.3rem;
                  font-size: $text-12;
                }
                .score-font {
                  font-size: $text-24;
                  font-weight: 600;
                  letter-spacing: 0.2rem;
                }
                .aet-font {
                  margin-top: -0.5rem;
                  font-size: $text-12;
                }
              }
            }
          }
        }
        //
        .wc-bg {
          img {
            width: 100%;
            height: auto;
          }
        }
        .form-check {
          .win-img {
            width: 2.5rem;
            height: 2.5rem;
            top: -1.2rem;
            right: 1.5rem;
          }
          .form-check-label {
            width: unset;
            min-width: 5.5rem;
            height: 7rem;
            img {
              width: 3rem;
            }
          }
        }
        .wc-notice {
          img {
            width: 100%;
          }
        }
      }
    }
    .wc-reward {
      padding: unset;
      .wc-reward-container {
        padding-bottom: 0.5rem;
        border-radius: 0.5rem;
        .wc-reward-header {
          padding: 0.5rem;
        }
        &.col-3 {
          width: 25%;
        }
        .wc-reward-body {
          font-size: $text-10;
        }
      }
    }
    .section-header-gray {
      h1 {
        color: $slate-gray-400;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      h5 {
        color: $gray-500;
      }
    }

    .list-group {
      flex-wrap: wrap;
      justify-content: space-around;
      flex-direction: row;
      margin-top: 0.5rem;
      .form-check {
        padding: 0;
        font-size: $text-12;
        width: 5rem;
        align-content: center;
        .form-check-label {
          // padding: 0.5rem 0rem;
          margin: 0rem 0rem 0.5rem 0rem;
          border: rem(1px) solid $slate-gray-200;
          border-radius: 0.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 5rem;
          img {
            margin-right: unset;
            margin-bottom: 0.5rem;
            border: rem(1px) solid $slate-gray-200;
            border-radius: 1rem;
          }
        }
        .form-check-input {
          opacity: 0;
          pointer-events: none;
          &:checked {
            + .form-check-label {
              color: $blue-500;
              border: rem(1px) solid $blue-500;
              position: relative;
              overflow: hidden;
              &::after {
                content: "";
                position: absolute;
                background: url("../../../images/profile/icons/checked.svg");
                background-repeat: no-repeat;
                background-size: contain;
                bottom: rem(-1px);
                right: rem(-1px);
                width: rem(25px);
                height: rem(25px);
                z-index: 1;
              }
            }
          }
        }
      }
    }

    .input-group-col {
      .input-group {
        .form-control {
          text-align: left;
          border-right: 0;
        }
        .input-group-text {
          background-color: white;
        }
      }
    }
  }

  .lb-event-date {
    justify-content: space-between;
    .date {
      justify-content: center;
      width: 100%;
      .status {
        color: white;
        width: 4rem;
        text-align: center;
        border-radius: 1rem;
        margin-top: unset;
        margin-left: 0.5rem;
        font-size: $text-12;
        &.deactive {
          background-color: $red-500;
        }
        &.active {
          background-color: $green-500;
        }
        &.pending {
          background-color: $gray-500;
        }
      }
    }
  }

  .wc-modal {
    .modal-content {
      .modal-body {
        padding: 3rem 0rem 0rem 0rem;
      }
    }
  }

  .fifa-two-body {
    width: unset;
    text-align: center;
    .vip-lvl-col {
      .vip-lvl-md {
        ul {
          li {
            span {
              width: 1.7rem;
              top: -1.2rem;
            }
          }
        }
      }
    }
  }

  .wc-check-result {
    .modal-dialog {
      .modal-content {
        padding: 0.5rem;
        .modal-body {
          background-color: white;
          box-shadow: $slate-gray-shadow;
          border-radius: 0.5rem;
          padding: 1rem 0rem;
          .result-body {
            padding: 1rem;
            width: 100%;
            p {
              padding-bottom: 0.2rem;
            }
            span {
              margin-right: 0.5rem;
            }
            &:nth-child(even) {
              background-color: $slate-gray-100;
            }
            img {
              box-shadow: rem(0px) rem(2px) rem(3px) $blue-400;
              border-radius: 1rem;
            }
          }

          .row {
            font-size: $text-12;
            p {
              margin-bottom: 0.3rem !important;
            }
            .col-4 {
              span {
                border-radius: 1rem;
                border: rem(1px) solid $gray-400;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 2rem;
              }
            }
          }
          .react-select__control {
            border-radius: 1rem !important;
            height: 2rem;
            align-content: center;
            border: rem(1px) solid $gray-400;
          }
        }
      }
    }
  }

  .fifa-two-qna {
    :has(.show) {
      .turn-btn {
        transform: rotate(180deg);
      }
    }
    .rh-m-md-panel {
      p {
        font-size: $text-12;
      }
      .btn-outline-blue {
        padding: 0.5rem;
        height: auto;
        margin: 0 auto;
        min-width: 5rem;
        width: 5rem;
      }
    }
    .team-body {
      padding: 0.5rem;
      font-size: $text-12;
      p{
        margin-bottom: 0.3rem!important;
      }
      .team-content {
        padding: 0rem 0.3rem;
      }
      .ans-content {
        border-top: rem(1px) solid $slate-gray-200;
        margin-top: 0.5rem;
        padding-top: 0.5rem;
      }
      &:nth-child(even) {
        background-color: $slate-gray-100 !important;
      }
    }
  }
}
