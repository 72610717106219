.check-in-section {
  .check-in-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    padding: 3.75rem;
    background-color: white;
    .check-in-col {
      height: rem(280px);
      width: rem(220px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border-radius: 1rem;
      background: linear-gradient(to bottom, #fbfcff, #eef5ff 30%);
      box-shadow: $slate-gray-card-shadow;
      color: $slate-gray-300;
      opacity: 0.4;
      pointer-events: none;
      &.active {
        opacity: 1;
        pointer-events: all;
      }
      &.current {
        box-shadow: 0 4px 12px 10px rgba($blue-500, 0.25);
      }
      h4 {
        margin: 0;
        text-align: center;
      }
      img {
        max-width: rem(120px);
      }
      p {
        font-size: $text-20;
        margin-bottom: 0.25rem !important;
      }
      .btn {
        width: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1.25;
          text-align: center;
        }
      }
    }
  }

  .table {
    padding-top: 0;
    margin: 0;
    thead {
      th {
        padding: 1rem 0.5rem;
      }
    }
    tr {
      display: flex;
      td,
      th {
        width: 50%;
        font-size: $text-14;
        &:first-child {
          border-right: rem(1px) solid rgba($slate-gray-200, 0.5);
        }
      }
    }
  }
  .event-time-table {
    .table {
      .event-session {
        width: 25%;
      }
      td,
      th {
        width: 75%;
      }
    }
  }

  .blue-bg-notice {
    background: linear-gradient(to right, $blue-400, $blue-600);
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
    color: white;
    font-size: $text-16;
    img {
      width: rem(172px);
      height: auto;
      margin-right: 1rem;
    }
  }
}
//mobile
.mobile-view {
  .check-in-section {
    .section-header-gray {
      &.mobile {
        margin-bottom: 1rem;
      }
    }
    .check-in-m-container {
      .check-in-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: $slate-gray-card-shadow;
        border-radius: 0.25rem;
        opacity: 0.4;
        &.active {
          opacity: 1;
        }
        &.current {
          box-shadow: 0 4px 12px 10px rgba($blue-500, 0.25);
        }
        h5,
        p {
          font-size: $text-10;
          color: $slate-gray-300;
        }
        h5 {
          width: 100%;
          text-align: center;
          padding: 0.5rem;
          margin: 0;
          background: white;
          box-shadow: 0 6px 16px 0 rgba($slate-gray-500, 0.1);
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        p {
          margin-bottom: 0.25rem !important;
        }
        img {
          max-width: rem(60px);
        }
        .btn {
          display: flex;
          align-items: center;
          height: 1.25rem;
          width: 4rem;
          min-width: unset;
          font-size: $text-10;
          padding: 0 0.25rem;
          margin-bottom: 0.5rem;
          span {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 1.25;
            text-align: center;
          }
        }
      }
    }
    .table {
      tr {
        th,
        td {
          font-size: $text-12;
        }
      }
    }
    .blue-bg-notice {
      font-size: $text-12;
    }
  }
}
