.lb-section {
  .banner-col {
    // .ld-banner-content{
    .banner-provider {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5rem;
      height: 5rem;
      position: absolute;
      top: 12%;
      left: 50%;
      transform: translateX(-50%);
      font-size: rem(22px);
      font-weight: $font-medium;
      z-index: 1;
      img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: auto;
        // width: 100%;
        // max-width: rem(164px);
        // height: rem(48px);
      }
    }

    .banner-date {
      position: absolute;
      top: 34%;
      left: 50%;
      transform: translateX(-50%);
      font-size: $text-18;
      font-weight: $font-medium;
      z-index: 1;
    }

    .banner-ranking-2,
    .banner-ranking-1,
    .banner-ranking-3 {
      position: absolute;
      bottom: 17%;
      right: 50%;
      transform: translateX(-184%);
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;

      .profile {
        width: rem(84px);
        height: rem(84px);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
      }

      .winner {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        font-size: $text-20;
        font-weight: $font-semibold;
        margin-top: 1rem;
        text-shadow: 0 1px 2px #827e9f;

        .prize {
          width: rem(155px);
          background: rgba(white, 0.8);
          text-align: center;
          border-radius: 1rem;
          margin-top: 0.5rem;
          font-weight: $font-medium;
          text-shadow: none;
          color: #6f6d84;
          box-shadow: 0 0 1px #827e9f;
        }
      }
    }

    .banner-ranking-1 {
      bottom: 14%;
      transform: translateX(48%);

      .profile {
        width: rem(110px);
        height: rem(110px);
      }

      .winner {
        font-size: $text-24;
        text-shadow: 0 1px 2px #d07524;

        .prize {
          width: rem(200px);
          color: #977221;
          box-shadow: 0 0 1px #d87d22;
        }
      }
    }

    .banner-ranking-3 {
      transform: translateX(273%);

      .winner {
        text-shadow: 0 1px 2px #ae6942;

        .prize {
          color: #b76736;
          box-shadow: 0 0 1px #9e5a35;
        }
      }
    }

    // }
  }

  .lb-event-date {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    .date {
      height: rem(38px);
      display: flex;
      align-items: center;
      padding: 0 1rem;
      border: 1px solid $blue-500;
      border-right: 0;
      border-left: 0;
      color: $slate-gray-500;
      font-size: $text-20;
      background: white;
      svg {
        margin-right: 0.5rem;
      }
      div {
        height: auto;
      }
    }
    .btn {
      min-width: 2.5rem;
      height: rem(38px);
      border: 1px solid $blue-500;
      border-radius: 0;
      font-size: $text-24;
      padding: 0.375rem;
      &:first-child {
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
      }
      &:last-child {
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
      }
      svg {
        margin: 0;
      }
    }
  }

  h1 {
    font-weight: $font-bold;
    padding: 0 0.25rem;
    margin: 0;
    color: $slate-gray-400;
    text-align: center;
  }

  .lb-game-container {
    .row {
      width: 80%;
      list-style-type: none;
      padding: 0;
      margin: 0 auto;

      .lb-game {
        background-image: url("../../../images/game-white.png");
        background-size: 120% 100%;
        background-position: center;
        background-repeat: no-repeat;
        color: $slate-gray-500;
        border-radius: 25px;
        display: flex;
        font-weight: $font-medium;
        font-size: $text-14;
        width: 100%;
        height: rem(52px);
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        align-items: center;
        justify-content: center;
        padding: inherit;
        cursor: pointer;
        box-shadow: $slate-gray-shadow;

        &.active {
          background-image: url("../../../images/game-blue.png");
          color: white;
          box-shadow: 0 4px 12px 0 rgba($blue-500, 0.25);

          .icon-grey {
            display: none;
          }

          .icon-white {
            display: block;
          }
        }

        &:hover {
          box-shadow: $slate-gray-hover-shadow;
        }

        span {
          width: 35%;
          padding-right: 5px;

          .icon-grey {
            margin: auto;
            display: block;
          }

          .icon-white {
            margin: auto;
            display: none;
          }
        }

        p {
          width: 65%;
          line-height: 1.2;
        }
      }
    }
  }

  .lb-name-list {
    .first-card,
    .second-card,
    .third-card {
      width: 95%;
      height: 12.25rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      box-shadow: $slate-gray-card-shadow;
      border-radius: rem(20px);
      padding: 1rem;
      margin-bottom: 1rem;

      @include shineEffect;

      .emblem {
        width: rem(161px);
        height: auto;
        // margin-left: 2rem;
      }

      .winner {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: $text-14;
        // margin-left: 2rem;

        .name {
          font-weight: $font-semibold;
          color: $slate-gray-800;
          font-size: $text-20;
          padding-bottom: 0.5rem;
        }

        .number {
          font-size: $text-18;
        }

        .bonus {
          color: $orange-500;
          padding: 0 1rem;
          background-color: white;
          border-radius: rem(20px);
        }
      }
    }

    .first-card {
      background-image: url("../../../images/leaderboard/top1-bg.webp");
    }

    .second-card {
      background-image: url("../../../images/leaderboard/top2-bg.webp");
    }

    .third-card {
      background-image: url("../../../images/leaderboard/top3-bg.webp");
      margin-bottom: 0;
    }

    .col-7 {
      .events-table {
        thead {
          tr {
            th {
              padding: 1.625rem 1rem;
              &:first-child {
                width: 20%;
              }
              &:nth-child(2) {
                width: 30%;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 1.625rem 1rem;
              &:first-child {
                width: 20%;
              }
              &:nth-child(2) {
                width: 30%;
              }
            }
          }
        }
      }
    }
  }

  .table {
    thead {
      tr {
        th {
          padding: 1rem 0.5rem;
          font-size: $text-18;
          font-weight: $font-medium;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 1rem 0.5rem;
          font-size: $text-16;
        }
      }
    }
  }

  .term-section {
    .events-table-container {
      border: 0;
      .table {
        tbody {
          td {
            border-right: rem(1px) solid $slate-gray-200;
          }
        }
      }
    }
  }
}

.mobile-view {
  .lb-section {
    .lb-event-date {
      margin-top: 0;
      .date {
        font-size: $text-14;
      }
    }
    .lb-game-container {
      .row {
        width: 100%;
        flex-wrap: nowrap;
        overflow: auto;
        padding: 1rem 0;
        &::-webkit-scrollbar {
          display: none;
        }
        .col-3 {
          margin-top: 0;
          .lb-game {
            background-image: url("../../../images/leaderboard/mobile/provider-btn-white.webp");
            width: 100%;
            height: rem(78px);
            flex-direction: column;
            font-size: $text-10;
            text-align: center;
            border-radius: rem(15px);
            &.active {
              background-image: url("../../../images/leaderboard/mobile/provider-btn-blue.webp");
            }
            &.coming-soon {
              background-image: none;
              background-color: #f2f2f2;
            }
            span {
              width: rem(25px);
              height: rem(25px);
              margin: 0 auto 0.5rem auto;
              .icon-grey,
              .icon-white {
                width: 100%;
                height: 100%;
              }
            }
            p {
              width: 100%;
              height: 1.5rem;
            }
          }
        }
      }
    }
    .lb-name-list {
      .first-card,
      .second-card,
      .third-card {
        width: 100%;
        height: rem(146px);
        margin: 1rem 0;
        .emblem {
          width: rem(120px);
        }
        .winner {
          width: 60%;
          margin-left: 0;
          align-items: unset;
          div {
            align-items: center;
            p {
              width: 40%;
              line-height: 1.2;
            }
            .number {
              width: 60%;
              text-align: center;
            }
            .bonus {
              margin-left: 0.5rem;
            }
          }
          .name {
            font-size: $text-18;
            text-align: center;
          }
        }
      }

      .lb-first-table,
      .lb-second-table {
        .events-table-container {
          overflow: auto;
          .events-table {
            thead {
              tr {
                th {
                  font-size: $text-14;
                }
              }
            }
            tbody {
              tr {
                td {
                  font-size: $text-14;
                }
              }
            }
          }
        }
      }
      .lb-first-table {
        .events-table {
          thead {
            tr {
              th {
                &:first-child {
                  width: 20%;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                &:nth-child(2),
                &:nth-child(3) {
                  max-width: 6rem;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
}
