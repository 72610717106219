// Global Modals //

.global-modal {
  .modal-dialog {
    max-width: 576px;
    .modal-content {
      border-radius: rem(15px);
      // background-image: url("../../../images/modal-bg.jpg");
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      // background-position: center;
      background: $dark-gray-800;
      // border: rem(2px) solid $dark-gray-600;
      &.tnc-content {
        background-image: none;
        .term-section {
          h4 {
            text-align: start;
            color: unset;
            font-weight: $font-medium;
          }
        }
      }
      &.modal-content-bg-transparent {
        background-image: none !important;
        background-color: transparent !important;
      }
      .modal-header {
        border-bottom: 0;
        h5 {
          // color: $blue-500;
          margin-top: auto;
          font-weight: $font-semibold;
          line-height: 1.5;
        }
        .btn-close {
          height: rem(25px);
          width: rem(25px);
          color: $slate-gray-800;
          background: unset;
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h4 {
          text-align: center;
          color: $blue-500;
          font-weight: $font-semibold;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  // .input-group {
  //   .input-group-text {
  //     background-color: white;
  //   }
  // }
}

.mobile-view {
  .global-modal {
    .modal-dialog {
      max-width: 90% !important;
    }
  }
}

.modal-language-body {
  ul {
    height: 260px;
    overflow: auto;
    list-style-type: none;
    padding: 0;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      border-bottom: 1px solid $slate-gray-200;
      &:last-child {
        border-bottom: 0;
      }
      .country {
        display: flex;
        align-items: center;
        img {
          width: 100%;
          max-width: 35px;
          height: auto;
        }
        h6 {
          margin: 0 1rem;
        }
      }
      .language {
        display: flex;
        justify-content: right;
        margin-right: 16px;
        .btn {
          height: 35px;
          border-radius: 25px;
          padding: 0.5rem 0;
          min-width: 120px;
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
        .btn-white {
          background: transparent;
          border: 1px solid $slate-gray-200;
        }
      }
    }
  }
}

.modal-pd {
  .modal-title {
    color: $slate-gray-800 !important;
  }
  .modal-body {
    padding-top: 0;
    display: block !important;
  }
}

.modal-referral {
  max-width: 576px;
  .modal-title {
    color: $slate-gray-800 !important;
  }
}

.modal-referral,
.m-referral {
  .ref-qr-code-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ref-qr-code {
      display: inline-block;
      box-shadow: $slate-gray-card-shadow;
      background-color: white;
      padding: 1rem;
      border-radius: .75rem;
    }
    h2,h3 {
      color: $blue-500;
      margin-bottom: 0;
      padding-top: 0.5rem;
    }
  }

  .or-line {
    width: 100%;
    text-align: center;
    position: relative;
    &::before {
      content: "";
      width: 45%;
      border: rem(0.25px) solid rgba($gray-50, 0.5);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &::after {
      content: "";
      width: 45%;
      border: rem(0.25px) solid rgba($gray-50, 0.5);
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  .list-group {
    width: 100%;
    margin-bottom: 1rem;
    .list-group-item {
      flex: 1;
      padding: 0.5rem;
      border: 0;
      text-align: center;
      background-color: unset;
      &:hover {
        p {
          color: $blue-500;
        }
      }
      img {
        width: rem(40px);
        height: auto;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: $text-12;
        color: $slate-gray-800;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .download-share-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .btn {
      width: 49%;
    }
  }
  .input-group {
    box-shadow: none;
    .input-group-text {
      height: rem(40px);
      padding: 0 !important;
      button {
        // border-radius: 0 0.25rem 0.25rem 0 !important;
        padding: 0 !important;
      }
    }
  }
}

.modal-verification {
  max-width: 576px;
  .modal-header {
    align-items: unset;
    & > div {
      width: calc(100% - 25px);
      .modal-title {
        color: $slate-gray-800 !important;
      }
      small {
        display: block;
        margin-top: 0.5rem;
      }
    }
  }
  .modal-body {
    padding-top: 0;
    .forms-col {
      width: 100%;
    }
    .btn-gradient-blue {
      width: 50%;
      margin: 0 auto;
    }
    .btn-underlined {
      float: right;
      color: $blue-500;
      text-decoration: underline;
      box-shadow: unset;
      padding-left: 0;
      padding-right: 0;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.modal-open {
  overflow: hidden;
  padding-right: 0;
}

.overlay {
  position: fixed;
  pointer-events: none;
  z-index: 90;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100vh;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  background: rgba(black, 0.5);
}
.open ~ .overlay {
  opacity: 1;
  pointer-events: unset;
}

//React Modal
.ReactModal__Overlay {
  background-color: rgba(#000, 0.5) !important;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  z-index: 1055;
  overflow: auto;
  &--after-open {
    opacity: 1;
    .ReactModal__Content--after-open {
      transform: none;
    }
  }
  &--before-close {
    opacity: 0;
    .ReactModal__Content {
      transform: translate(0, -50px);
    }
  }
}

.ReactModal__Content {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
  &:focus-visible {
    outline: 0;
  }
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

// @include max-lg {
//   .modal-dialog{
//     max-width: unset;
//     margin: 0.5rem;
//   }
// }

//upgrade business acc modal content
.upgrade-acc-modal {
  padding: 1rem;
  .modal-header {
    align-items: unset;
    padding: 0;
    h5 {
      color: $slate-gray-800 !important;
      margin-bottom: 0;
    }
    p {
      font-size: $text-12;
    }
  }
  .modal-body {
    padding: 0;
    h5, h6{
      padding-top: 1rem;
      color: $slate-gray-800;
      font-weight: $font-semibold;
    }
    .upgrade-acc-benefit {
      height: 16rem;
      padding: 0;
      margin: 0;
      overflow: auto;
      list-style: none;
      li {
        margin-bottom: 1rem;
        p {
          font-weight: $font-semibold;
        }
      }
    }
    .row {
      width: 100%;
    }
    .continue-btn-container {
      display: flex;
      justify-content: center;
      .btn {
        min-width: 8rem;
      }
      .btn-gradient-blue {
        margin: 0;
      }
    }
    .btn-gradient-blue {
      display: block;
      margin: 0 auto;
      min-width: 16rem;
    }
  }
}

.mobile-modal-bg-white {
  &.modal-content {
    background-image: unset!important;
    background-color: unset!important;
  }
  .modal-header {
    padding: 0.8rem 0.5rem;
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    .modal-title {
      font-size: $text-14;
    }
  }
  .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .modal-body {
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;

    padding: 0.5rem 0rem;
    font-size: $text-12;
    .modal-body-cont {
      width: 100% !important;
    }
  }
  .btn-gray {
    width: 100%;
    height: 2.5rem;
    margin-top: 0.5rem;
    background-color: #b0b0b0;
  }
  .btn-white, .btn-dark-gray {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
    border-top: 1px solid;
    background-color: #fff!important;
    padding: 0.8rem;
    background:unset;
    color: $blue-500;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .btn-dark-gray {
    border-top-color: $gray-50;
    background-color: $dark-gray-700 !important;
  }
}

//subscribe modal
#onesignal-slidedown-container.slide-down{
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
}