@mixin no-appearance {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $color;
  }

  &::placeholder { /* Most modern browsers support this now. */
    color: $color;
  }
}

@mixin no-autofill-bg($color) {
  &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active {
    transition: background-color 0s ease-in-out 50000s;
    animation-name: autofill;
    animation-fill-mode: both;
    -webkit-text-fill-color: $color !important;
  }
}


@mixin no-focus-hover($color) {
  transition: all .3s ease-in-out;
  &:hover {
    text-decoration: none;
    color: $color;
  }
  &:focus {
    outline: 0;
  }
}
@mixin min-sm {
  @media (min-width: 576px) { @content; }
}
@mixin min-md {
  @media (min-width: 768px) { @content; }
}
@mixin min-lg {
  @media (min-width: 992px) { @content; }
}
@mixin min-xl {
  @media (min-width: 1200px) { @content; }
}

@mixin max-sm {
  @media (max-width: 575px) { @content; }
}
@mixin max-md {
  @media (max-width: 767px) { @content; }
}
@mixin max-lg {
  @media (max-width: 991px) { @content; }
}
@mixin max-xl {
  @media (max-width: 1199px) { @content; }
}

@mixin triangle-bottom($color, $width, $height) {
  $finalWidth: $width / 2;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: #{$height}px #{$finalWidth}px 0 #{$finalWidth}px;
  border-color: $color transparent transparent transparent;
  pointer-events: none;
}

@mixin hideScrollbars {
  -ms-overflow-style: none; // IE 10+
  &::-webkit-scrollbar {
    display: none;
  }
}
// .gap-x-1,
// .gap-x-2,
// .gap-x-3,
// .gap-x-4,
// .gap-x-5 {
//   // margin-right: calc(var(--gap) * 0);
//   // margin-left: calc(var(--gap) * calc(1 - 0));
//   // width: calc(100% + var(--gap));
//   grid-gap: unset !important;
//   gap: unset !important;
// }

.gap-x-1 > * + * {
  margin-right: calc(var(--gap-x) * 0);
  margin-left: calc(var(--gap-x) * calc(1 - 0));
}
.gap-x-2 > * + * {
  margin-right: calc(var(--gap-x) * 0);
  margin-left: calc(var(--gap-x) * calc(1 - 0));
}
.gap-x-3 > * + * {
  margin-right: calc(var(--gap-x) * 0);
  margin-left: calc(var(--gap-x) * calc(1 - 0));
}
.gap-x-4 > * + * {
  margin-right: calc(var(--gap-x) * 0);
  margin-left: calc(var(--gap-x) * calc(1 - 0));
}
.gap-x-5 > * + * {
  margin-right: calc(var(--gap-x) * 0);
  margin-left: calc(var(--gap-x) * calc(1 - 0));
}

.gap-x-1 {
  --gap-x: 0.5rem;
}

.gap-x-2 {
  --gap-x: 0.5rem;
}

.gap-x-3 {
  --gap-x: 1rem;
}

.gap-x-4 {
  --gap-x: 1.5rem;
}

.gap-x-5 {
  --gap-x: 3rem;
}

.gap-y-1 > * + * {
  margin-bottom: calc(var(--gap-y) * 0);
  margin-top: calc(var(--gap-y) * calc(1 - 0));
}
.gap-y-2 > * + * {
  margin-bottom: calc(var(--gap-y) * 0);
  margin-top: calc(var(--gap-y) * calc(1 - 0));
}
.gap-y-3 > * + * {
  margin-bottom: calc(var(--gap-y) * 0);
  margin-top: calc(var(--gap-y) * calc(1 - 0));
}
.gap-y-4 > * + * {
  margin-bottom: calc(var(--gap-y) * 0);
  margin-top: calc(var(--gap-y) * calc(1 - 0));
}
.gap-y-5 > * + * {
  margin-bottom: calc(var(--gap-y) * 0);
  margin-top: calc(var(--gap-y) * calc(1 - 0));
}

.gap-y-1 {
  --gap-y: 0.5rem;
}

.gap-y-2 {
  --gap-y: 0.5rem;
}

.gap-y-3 {
  --gap-y: 1rem;
}

.gap-y-4 {
  --gap-y: 1.5rem;
}

.gap-y-5 {
  --gap-y: 3rem;
}

@mixin shineEffect {
  .lazy-load-image-background {
    position: relative;
    overflow: hidden;
    &:after {
      animation: shine 3s ease-in-out infinite;
      animation-fill-mode: forwards;
      content: "";
      position: absolute;
      width: 100%;
      height: 200%;
      opacity: 1;
      transform: rotate(30deg);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 100%,
      );
    }
  }
}