.about-us-banner {
  position: relative;
  .section-header {
    width: 70%;
    height: auto;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    p {
      font-size: $text-14;
    }
  }
}

.mission-section,
.customer-section {
  position: relative;
}

.mission-section {
  span {
    img {
      max-width: 800px;
      height: auto;
    }
  }
  .section-two-content {
    h1 {
      text-transform: uppercase;
      padding-bottom: 1rem;
    }
    p {
      padding: 0 0 0 2.5rem;
    }
  }
}

.global-section-two {
  .mission-bg {
  background-image: url("../../../images/about-us/mission-main-bg.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 650px;
  }
}

.product-bg {
  background-image: url("../../../images/about-us/product-main-bg.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 650px;  
}

.mission-section {
  .asset-1 {
    top: 0%;
    right: 3%;
    max-width: 40%;
  }
  .lazy-load-image-background.blur.lazy-load-image-loaded {
    position: absolute;
    top: -15%;
    right: -3%;
    .section-two-img {
      position: unset;
    }
  }
}

.customer-section {
  h1, h2 {
    text-transform: uppercase;
    margin-bottom: unset;
  }
  .lazy-load-image-background.blur.lazy-load-image-loaded {
    position: absolute;
    top: -6%;
    right: 2%;
    .section-two-img {
      position: unset;
      max-width: 460px;
    }
  }

  .section-two-content {
    left: 10%;
    p {
      height: auto;
    }
    a {
      text-decoration: underline;
      text-decoration-color: $blue-500;
    }
  }
}

.product-section {
  margin: -10rem 0 10rem 0;
  .row {
    .col:first-child {
      width: 100%;
      max-width: 240px;
      position: relative;
      .sideBar-bg {
        height: 508px;
      }
    }
    .product-sideBar-container {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      list-style-type: none;
      padding: 3rem 1rem 1rem 1rem;
      display: grid;
      li {
        display: flex;
        justify-content: center;
        cursor: pointer;
        .product-sideBar {
          display: flex;
          padding: 0.5rem;
          align-items: center;
          background-image: url("../../../images/game-white.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          height: 55px;
          width: 90%;
          transition: all 0.3s ease-in-out;
          span {
            width: 75px;
            padding: 1rem;
            .ctg-icon {
              width: 100%;
              max-width: 45px;
              height: auto;
              filter: grayscale(1);
              transition: all 0.3s ease-in-out;
            }
          }
          p {
            transition: all 0.3s ease-in-out;
          }
        }

        &:hover,
        &.active {
          .product-sideBar {
            background-image: url("../../../images/game-blue.png");
            .ctg-icon {
              filter: unset;
            }
            p {
              color: $blue-500;
            }
          }
        }
      }
    }

    .col:last-child {
      position: relative;
      display: flex;
      align-items: center;
      .card-shadow {
        width: 100%;
        margin-left: 5rem;
        // img {
        //   height: 430px;
        // }
      }
      .product-img {
        width: 100%;
        max-width: 490px;
        height: auto;
        position: absolute;
        bottom: 14%;
        left: -2%;
      }
      // .lazy-load-image-background.blur.lazy-load-image-loaded {
      //   position: absolute;
      //   bottom: 0%;
      //   left: -6%;
      //   .product-img {
      //     width: 100%;
      //     max-width: 550px;
      //     height: auto;
      //   }
      // }
      .product-content {
        position: absolute;
        width: 50%;
        height: auto;
        right: 2%;
        padding: 1rem;
        text-align: center;
        h1 {
          color: $slate-gray-500;
          font-weight: $font-semibold;
          text-transform: uppercase;
        }
        .provider-list,
        .slick-slider {
          list-style-type: none;
          display: flex;
          width: 100%;
          overflow: auto;
          padding: 1rem 0;
          .card-shadow {
            margin-left: 0;
            border-radius: 0.25rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            img {
              width: 80px;
              height: 70%;
            }
            &:hover {
              box-shadow: $blue-shadow;
            }
          }
          .slick-list {
            padding: 1rem 0;
          }
        }
        .slick-slider {
          padding: 0 !important;
          .card-shadow {
            width: 80px !important;
          }
        }
      }
    }
  }
}

.section-m-container {
  padding: 1rem .5rem 1.5rem .5rem !important;
  h1 {
    margin-bottom: unset;
  }
  p {
    a {
      text-decoration: underline;
      text-decoration-line: $blue-500;
      text-decoration-thickness: 0.075rem;
    }
  }
}

//mobile product section
.product-m-section {
  .game-m-nav {
    .game-nav-btn {
      background-image: url("../../../images/game-white.png");
      p {
        padding-left: .2rem;
      }
      .nav-img {
        filter: grayscale(1);
      }
      &.active {
        background-image: url("../../../images/game-blue.png");
        .nav-img {
          animation: zonIn 0.5s;
          display: block !important;
          filter: grayscale(0);
          opacity: 1 !important;
        }
        p {
          color: $blue-500;          
        }
      }
    }
  }
  .product-m-image {
    min-height: 14rem;
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
    img {
      width: 100%;
      height: auto;
      min-width: rem(298px);
      margin-bottom: -2rem;
    }
  }

  .row {
    list-style-type: none;
    padding: 0%;
    .card-shadow {
      border-radius: 0;
    }
  }
  .slick-slider {
    padding: 0 !important;
    .card-shadow {
      width: rem(55px) !important;
    }
  }
}
