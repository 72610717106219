video {
  height: 650px;
}

.info-center-section {
  .top-nav {
    border-bottom: 1px solid $gray-50;
    .nav-link {
      &.active {
        border-bottom: 2px solid;
        border-radius: unset;
        background-color: unset;
        color: $blue-500;
        p {
          color: $blue-500;
          font-size: $text-14;
          font-weight: 500;
        }
      }
      p {
        font-weight: 500;
        color: $gray-50;
      }
    }
  }

  .sidebar-nav {
    .nav {
      background-color: #fff;
      border-radius: 20px;
      padding: 2rem 0;
      .nav-link {
        padding: 1rem;
        border-radius: unset;
        color: $slate-gray-800;
        &.active {
          background-color: $blue-100;
          border-left: 3px solid;
          border-color: $blue-500;
          color: $blue-500;
        }
        &:hover {
          color: $blue-500;
        }
      }
    }
    .card-shadow {
      display: flex;
    }
    &.mobile{
      padding: 1rem;
      .nav-link{
        border-radius: unset;
        border-bottom: 1px solid rgba($blue-400,0.5);
        padding: 1rem 0rem;
        &.active {
          border-color: unset!important;
          color: unset!important;
        }
        &:last-child{
          border-bottom: unset;
        }
      }
    }
  }

  .info-content {
    background-color: $black-600;
    border-radius: .7rem;
    padding: 2rem;
    box-shadow: $slate-black-shadow;
    border: 1.5px solid $black-500;
  }

  .tab-content {
    .accordion {
      .accordion-item {
        margin-bottom: 0.75rem;
        border: unset;
        background-color: unset;
        .accordion-button {
          h6 {
            font-weight: 500;
            margin-bottom: unset;
          }
          &.collapsed {
            border: 2px solid $gray-50;
            background-color: unset;
            border-radius: .5rem;
            color: $slate-gray-500;
            &::after {
              content: "";
              background: url("../../../images/info-centre/arrow-down.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
            }
          }
          &:not(.collapsed) {
            background-color: $black-900;
            box-shadow: unset;
            border-radius: .5rem;
            color: $slate-gray-500;
            &::after {
              content: "";
              background: url("../../../images/info-centre/arrow-up.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              transform: rotate(0deg);
            }
          }
        }
        .accordion-collapse {
          .accordion-body {
            .li-alphabet{
              list-style-type: lower-alpha;
            }
            .li-roman{
              list-style-type: lower-roman;
            }
            .watch-video {
              display: flex;
              gap: 0.5rem;
              color: $blue-500;
              span {
                padding-top: .2rem;
                text-decoration-line: underline;
                text-decoration-color: $blue-500;
                text-decoration-thickness: 0.1rem;
              }
              &.mobile {
                img {
                  width: 60px;
                }
              }
            }
            span {
              text-decoration: underline;
              text-decoration-thickness: 0.075rem;
              padding: 0rem 0.15rem;
              cursor: pointer;
            }
            img{
              width: 25px;
              height: auto;
              padding-bottom: 1rem;
            }
            h6 {
              font-weight: 700;
              margin-bottom: 0rem !important;
            }
            p {
              margin-bottom: 1rem !important;
              color: $slate-gray-500;
            }
            ol {
              padding-left: 1rem;
            }
            ul {
              padding-left: 4.2rem;
            }
            .custom-list {
              list-style-type: none;
              padding-left: 3.2rem;
              margin-bottom: 0px;
              label {
                float: left;
                margin: 0 0 0 rem(-51px);
              }
            }
            &.info-cont-w-count {
              .custom-list-count {
                counter-reset: item;
                padding-left: 0.8rem;
                margin-bottom: 0rem;
                li {
                  display: block;
                  &::before {
                    content: counters(item, ".") ". ";
                    counter-increment: item;
                    margin-left: rem(-12px);
                  }
                }
              }
              .custom-list-count-2 {
                li {
                  padding-left: 2rem!important;
                  &::before {
                    margin-left: rem(-25px)!important;
                  }
                }
              }
            }
            .btn-outline-blue{
              display: flex;
              justify-content: center;
              align-items: center;
              width: rem(400px);
              margin: 0 auto;
            }
          }
        }
      }
    }
    .info-tnc {
      .tnc-custom-list {
        padding-left: 1rem;
        list-style-type: none;
        margin-bottom: 0px;        
        li {
          padding-left: 1rem;
        }
        label {
          // float: left;
          position: relative;
          top: 1.5rem;
          margin: 0 0 0 -1rem;
          padding-top: 0.1rem;
          color: $blue-500;          
          border: 1px solid $blue-500;
          border-radius: 100%;
          width: 25px;
          text-align: center;
        }
        &.mobile {
          label {
            border: 3px solid $blue-500;
            width: 55px;
          }
        }
      }
    }
    .info-rg {
      p {
        margin: 1.5rem 0rem;
        &.blue-text {
          color: $blue-500;
          font-weight: 500;
        }
      }
      a {
        color: $blue-500;
        text-decoration-line: underline;
        text-decoration-color: $blue-500;
        text-decoration-thickness: 0.075rem;
      }
      .rg-custom-list {
        list-style-type: disc;
        margin: 1.5rem -1rem 0rem;
        li::marker {
          font-size: 0.6rem;
        }
      }
    }
  }


//mobile info centre
&.ic-m-section {
  .tab-content-container {
    background: $black-600;
    margin-top: 1.5rem;
    padding: 1.5rem;
    // box-shadow: $slate-gray-card-shadow;
    .tab-content {
      .accordion {
        .accordion-item {
          border-radius: rem(10px);
          .accordion-header {
            .accordion-button {
              h6 {
                font-weight: $font-regular;
              }
              &.collapsed {
                border-radius: rem(10px);                                       
              }
              &:not(.collapsed) {
                border: rem(1px) solid $blue-500;
                color: $blue-500;
              }
            }
          }
          .accordion-body {
            ul {
              padding-left: 2rem;
            }
            img {
              width: 100%;
              height: auto;
              padding-bottom: 1rem;
            }
            .btn-gradient-blue {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: rem(50px);
              border-radius: rem(10px);
              color: #fff;
            }
          }
        }
      }
    }
  }

  .faq-selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 1rem;
    margin-top: 1rem;
    box-shadow: $slate-gray-card-shadow;
    svg {
      font-size: $text-24;
    }
  }
 }
}