.bottom-nav-section {
  // bottom: 0;
  // position: fixed;
  // width: 100%;
  // z-index: 999;
  .bottom-nav-content {
    width: 100%;
    height: 3.5rem;
    display: flex;
    justify-content: space-around;
    background-color: $black-800;
    margin: 0;
    align-items: center;
    padding-left: 0;
    // box-shadow: 0 0 30x 10px rgba($slate-gray-500, 0.25);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    li {
      list-style-type: none;
      width: 20%;
      height: auto;
      line-height: 1;
    }
    .nav-link {
      display: grid;
      text-align: center;
      // color: $slate-gray-300;
      color: $slate-gray-500;
      position: relative;
      font-size: $text-12;
      padding: 0;
      .bottom-icon-active {
        display: none;
      }
      &.active {
        color: $blue-600;
        .bottom-icon-active {
          animation: zonIn 0.5s;
          display: block;
          opacity: 1 !important;
        }
        .bottom-icon {
          display: none;
        }
      }
      span {
        position: absolute;
        top: -25%;
        right: -5%;
        width: max-content;
        border: rem(1px) solid white;
        border-radius: .5rem;
        font-size: rem(8px);
        color: white;
        background-color: $red-500;
        padding: .25rem;
      }
      img {
        margin: 0 auto 0.25rem auto;
        width: 1.75rem;
        height: 1.75rem;
        // object-fit: cover;
      }
      &.home-btn {
        width: 4rem;
        height: auto;
        object-fit: contain;
        margin: 0 auto;
        top: -0.75rem;
        .bottom-icon {
          width: 100%;
          height: auto;
          // box-shadow: 0rem 0rem 0.2rem 0.1rem rgba($blue-500, 0.40);
          // box-shadow: 0rem 0.2rem 0.2rem rgba($blue-500, 0.4);
          border-radius: 3rem;
        }
        .bottom-icon-active {
          display: none;
        }
        &.active {
          .bottom-icon-active {
            width: 100%;
            height: auto;
            // box-shadow: $slate-gray-shadow;
            border-radius: 3rem;
            display: block;
          }
          .bottom-icon {
            display: none;
          }
        }
      }
    }
    .badge {
      position: absolute;
      top: -18%;
      right: 10%;
    }
  }
}
