.fb-mf-col {
  margin: 0 -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  .list-group {
    height: 0;
    .list-group-item {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      border-color: $slate-gray-200;
      margin: 0 -1.25rem;
      cursor: pointer;
      transition: all .3s ease-in-out;
      &:hover {
        background-color: $blue-100;
      }
       label {
        font-size: $text-12;
        font-weight: $font-medium;
        color: $slate-gray-800;
        margin-bottom: .5rem;
       }
       p {
        font-size: $text-12;
        color: $slate-gray-500;
        line-height: $leading-tight ;
       }
     }
  }
}