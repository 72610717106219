// My Wallet //
.referral-tp-panel {
  .row {
    .col-6 > div {
      display: flex;
      align-items: center;
      padding: 1rem;
      border: 1px solid $slate-gray-200;
      border-radius: 0.5rem;
      height: 100%;
      img,
      svg {
        margin-right: 7px;
      }
      p {
        color: $slate-gray-800;
        font-weight: $font-medium;
        span {
          font-size: $text-20;
        }
      }
    }
  }
}

.pf-m-dashboard {
  .referral-tp-panel {
    background-color: $dark-gray-700;
    // box-shadow: $slate-gray-card-shadow;
    .col-6 {
      position: relative;
      & > div {
        border: unset !important;
        flex-direction: column;
        text-align: center;
      }
      &:nth-child(2):after {
        content: "";
        background: $slate-gray-200;
        position: absolute;
        bottom: 25%;
        left: 0;
        height: 50%;
        width: rem(1px);
        // border-left: rem(1px) solid $slate-gray-200;
      }
      img {
        width: 3rem;
        height: 3rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.m-referral {
  padding: 1rem;
  background-color: $dark-gray-700;
  box-shadow: $slate-gray-card-shadow;
  .input-group {
    align-items: center;
  }
  .list-group {
    margin-top: 1rem;
    flex-wrap: wrap;
    // margin: 1rem -0.5rem 0 -0.5rem;
    .list-group-item {
      flex: unset;
      width: 25%;
    }
  }
}
// .mobile-refer-friend {
//   background-color: #fff;
//   padding: 1rem 1rem 1.5rem 1rem;
//   margin-top: 1rem;
//   .list-group {
//     flex-direction: unset;
//     flex-wrap: wrap;
//     .list-group-item {
//       width: 25%;
//       text-align: center;
//       border: unset;
//       padding: 1.5rem 0rem 0rem 0rem;
//       img {
//         width: 2.5rem;
//       }
//       p {
//         padding-top: 0.25rem;
//       }
//     }
//   }
//   .input-group {
//     padding-top: 0.5rem;
//     .form-control {
//       background-color: #fff;
//     }
//     .input-group-text {
//       padding: unset;
//       .btn-gradient-blue {
//         border-bottom-left-radius: unset;
//         border-top-left-radius: unset;
//       }
//     }
//   }
// }

//Mobile Referral
.pf-m-dashboard {
  .pd-content {
    .referral-tp-panel {
      .row {
        width: 100%;
        margin: 0;
        .col-6 {
          margin: 0;
          p{
            font-size: $text-20;
            small {
              font-size: $text-10;
              margin: 0 .25rem;
            }
          }
        }
      }
    }
  }
  .m-referral {
    .input-group {
      .form-control {
        flex: unset;
        width: 70%;
      }
      .input-group-text {
        width: 30%;
        .btn-gradient-blue {
          height: 2.5rem;
          width: 100%;
          min-width: unset;
          top: 0;

        }
      }
    }
  }
}
