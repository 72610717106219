//Personal Information//
.pi-sec-lvl-col {
  display: grid;
  gap: 0.5rem;

  .pi-sec-lvl-content {
    display: flex;
    align-items: center;

    &>p {
      font-size: $text-16;
      color: $slate-gray-800;
      margin-right: 0.5rem;
    }

    .pi-sec-lvl-bar {
      display: flex;
      align-items: center;

      &>p {
        font-size: $text-16;
        font-weight: $font-medium;
      }

      span {
        border-radius: 1rem;
        margin: 0 0.5rem;
        height: rem(5px);
        width: rem(30px);
      }

      &.low {

        &>p,
        &>small {
          color: $red-500;
        }

        span:nth-child(n - 2) {
          background-color: $red-500;
        }

        span:nth-child(n + 3) {
          background-color: $red-100;
        }
      }

      &.normal {

        &>p,
        &>small {
          color: $orange-500;
        }

        span:nth-child(n - 3) {
          background-color: $orange-500;
        }

        span:nth-child(n + 4) {
          background-color: $orange-100;
        }
      }

      &.strong {

        &>p,
        &>small {
          color: $green-500;
        }

        span {
          background-color: $green-500;
        }
      }

      &.sec-m-lvl {
        &>p {
          font-size: $text-14;
          font-weight: $font-semibold;
        }
      }
    }
  }
}

.pi-progress-col {
  .navbar-nav {
    flex-direction: row;

    .nav-item {
      display: flex;
      align-items: center;
      flex: 1;

      &:nth-child(n + 1):not(:last-child) {
        margin-right: 0.75rem;
      }

      &:not(.active) {
        .inactive {
          display: block;
        }

        .active {
          display: none;
        }
      }

      &.active {
        .inactive {
          display: none;
        }

        .active {
          display: block;
        }
      }

      img {
        max-width: 40px;
        width: 100%;
        height: auto;
        margin-right: 7px;
      }

      p {
        font-size: $text-12;
        line-height: $leading-tight;
      }
    }
  }
}

.pi-bh-col,
.fb-mf-col,
.pi-bd-col {
  position: relative;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.pi-bh-col {
  .list-group {
    height: 0;

    .list-group-item {
      padding-left: 0;
      padding-right: 0;
      border-color: $slate-gray-200;
      background-color: unset;

      span {
        display: flex;
        justify-content: space-between;
        margin: 0.25rem 0;

        &>* {
          font-size: $text-12;
          width: calc(50% - 0.5rem);
        }

        label {
          color: $gray-50;
          font-weight: $font-medium;
        }

        p {
          color: $slate-gray-500;
        }
      }
    }
  }
}

.pi-bd-col {
  .list-group {
    display: grid;
    gap: 10px;
    height: 0;

    .list-group-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
      border: 1px solid $slate-gray-200;
      border-radius: 0.5rem;
      transition: all 0.3s ease-in-out;
      background-color: unset;

      &:hover {
        border-color: $blue-500;

        p,
        svg {
          color: $blue-500;
        }
      }

      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }

      p {
        width: 80%;
        font-size: $text-12;
        color: $slate-gray-500;
        font-weight: $font-medium;
      }

      svg {
        color: $slate-gray-500;
        width: 20px;
        height: 20px;
      }
    }
  }
}

//Mobile personal information
.pi-m-section {
  ul {
    list-style-type: none;
    padding: 0;
  }

  .pi-m-header {
    width: 100%;
    background-image: url("../../../../images/profile/mobile/header-bg.webp");
    background-position: 100% rem(-150px);
    background-size: cover;
    background-repeat: no-repeat;

    .pi-edit {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 40px;
      color: $slate-gray-800;

      svg {
        font-size: $text-24;
      }
    }

    .pi-m-info {
      width: 70%;
      margin: 0 auto;
      padding: 0 1rem;
      justify-content: center;

      .user-info {
        .us-balance {
          font-size: $text-18;
          font-weight: $font-medium;

        }

        .us-join {
          font-size: $text-12;
          color: $gray-50;
        }
      }
    }

    .pi-m-amount {
      width: 100%;
      height: auto;
      padding: 1rem 1rem 0 1rem;

      .amount {
        background: rgba(255, 255, 255, 0.1);
        padding: 1rem;
        border-radius: rem(10px);
        text-align: center;
        backdrop-filter: blur(5px);

        .number {
          font-size: $text-24;
          color: $slate-gray-800;
        }

        .coin-earning {
          background: $dark-gray-700;
          border: rem(2px) solid $dark-gray-600;
          color: $slate-gray-800;

          .number {
            font-size: $text-16;
          }
        }
      }
    }

    .pi-deposit-withdrawal {
      display: flex;
      justify-content: space-between;
      gap: .5rem;
      padding: 1rem;

      .btn {
        border-radius: rem(10px);
        height: rem(55px);
        width: 100%;
        background: $dark-gray-700;
        border: rem(2px) solid $dark-gray-600;
        color: $slate-gray-800;

        img {
          width: 2rem;
          height: auto;
        }
      }
    }

    .pi-selection {
      padding: 0 1rem;
      display: flex;
      justify-content: center;

      .row {
        width: 100%;
        margin-bottom: 0;
        background: $dark-gray-700;
        border: rem(2px) solid $dark-gray-600;
        color: $slate-gray-800;
        border-radius: rem(10px);
        padding: 1rem 0;

        // box-shadow: $slate-gray-card-shadow;
        .col-3 {
          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            color: $slate-gray-800;

            img {
              width: rem(40px);
              height: rem(40px);
              margin-bottom: 0.5rem;
            }

            p {
              line-height: 1.2;
            }
          }

          font-size: $text-12;
          border-right: 1px solid $slate-gray-200;

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
    .m-subHeader{
    &.bg-transparent {
      img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.75rem;
      }
    }}
  }

  .pi-m-option {
    margin-top: 1rem;

    .option-img {
      width: rem(27px);
      height: rem(27px);
    }

    .option-img,
    .badge {
      margin: 0 0.5rem;
    }

    .pi-mall-redemption {
      display: flex;
      width: 100%;
      background: white;
      box-shadow: $slate-gray-card-shadow;

      li {
        .nav-link {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem;
          font-size: $text-12;
          color: $slate-gray-800;

          p {
            text-align: center;
          }
        }

        width: 50%;
        border-right: 1px solid $slate-gray-200;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .pi-m-list {
      padding: 0 1rem;
      background: $dark-gray-700;
      color: $slate-gray-800;

      li {
        a {
          display: flex;
          align-items: center;
          color: $slate-gray-800;

          svg {
            margin-left: auto;
            color: $gray-50;
            font-size: $text-36;
          }
        }

        width: 100%;
        padding: 1rem 0;
        border-bottom: 1px solid $gray-50;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

//Mobile Edit Personal Info
.ei-m-section {
  width: 100%;
  height: auto;

  .pi-m-info {
    background: $dark-gray-700;
    padding: 1rem;

    // box-shadow: $slate-gray-card-shadow;
    .user-profile-container {
      width: 20%;
    }

    .user-info {
      width: 80%;
    }

  }

  .pf-m-dashboard {
    background: $dark-gray-700;
  }

  .forms-col {
    background: $dark-gray-700;
    // box-shadow: $slate-gray-card-shadow;
    padding: 1rem;

    .input-group-col {
      padding-bottom: 1rem;
      border-bottom: 1px solid $slate-gray-300;

      .input-group {
        .form-control {
          width: 55%;
          border: 0;
          padding-left: 0%;
          color: $slate-gray-500;
          word-break: break-all;
          text-transform: capitalize;

          &.note-text {
            font-size: $text-14;
          }         
        }

        .input-group-text {
          border: 0;
          margin-left: auto;

          svg {
            font-size: 1.5rem;
            color: $gray-50;
          }
        }
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}

//Mobile Add Security Pin
.sp-m-section {
  .input-group-col {
    h5 {
      color: $slate-gray-800;
      font-weight: normal;
      margin: 0;
    }

    span {
      color: $slate-gray-300;
      font-size: $text-12;
    }

    .forget-pin {
      color: $red-500;
      text-align: start;
      text-decoration: underline;
      font-size: $text-12;
    }
  }
}

//Mobile Verificatiob
.verification-m-section,
.sp-m-section {
  .css-2b097c-container {
    width: 30%;
  }

  .input-group.left {
    .react-select__control {
      min-width: unset;
      border: 0 !important;
      background-color: $dark-gray-700 !important;
      color: $slate-gray-800;
    }
  }

  .form-control {
    &.is-valid {
      background-image: url("../../../../images/register/icon-checked.webp");
      background-repeat: no-repeat;
      background-size: 1.25rem;
      background-position: right;
    }
  }

  .verificate-m-input {
    display: flex;
    align-items: center;

    .form-control {
      width: 80%;
      padding-left: 0;
    }

    small {
      color: $blue-500;
      margin-right: .5rem;
    }

    .btn {
      min-width: rem(77px);
      height: rem(30px);
      border-radius: .25rem;
      font-size: $text-12;
      background: $blue-500;
    }

    input {
      &::placeholder {
        color: $gray-50;
      }
    }
  }

  .m-subHeader {

    .sub-link {
      width: auto;
    }

    h6 {
      width: 100%;
    }
  }

  .edit-sec-header {
    .sub-link {
      width: 25%;
    }
  }
}

//Mobile Third Party
.third-party-m-auth {
  .third-party-m-auth-container {
    margin-top: 1rem;
    background: $dark-gray-700;
    padding: 0 1rem;
    // box-shadow: $slate-gray-card-shadow;

    .third-party-list {
      li {
        border: 0;
        border-bottom: rem(1px) solid $slate-gray-200;
        padding: 1rem;
        background: $dark-gray-700;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

.sp-mention {
  text-align: center;
  font-size: $text-12;
  color: $slate-gray-300;
  padding: 0 1rem;

  a {
    text-decoration: underline;
  }
}

//Mobile Business Acc upgrade
.upgrade-acc-m-section {
  .m-subHeader {
    .sub-link {
      width: auto;
    }

    h6 {
      width: 100%;
    }

  }

  .forms-col {
    .pd-form-m-col {
      .input-group-col {
        .dropzone-container {
          margin-bottom: 0.5rem;
        }
      }

      small {
        color: $slate-gray-300;
      }
    }
  }
}

//Mobile Business Acc info 
.business-m-info {
  .pd-form-m-col {
    .input-group-col {
      .col-form-label {
        padding: 0;
        color: $slate-gray-800;
      }
    }
  }

  .footer-desc {
    text-align: center;
    padding: 0 1rem;
    font-size: $text-12;
    color: $slate-gray-400;

    span {
      text-decoration: underline;
      color: $blue-500;
    }
  }
}