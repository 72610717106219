.cert-page-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  ul {
    display: flex;
    padding-left: 0;
    .cert-btn {
      background-image: url("../../../images/game-white.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: $slate-gray-500;
      border-radius: rem(25px);
      display: flex;
      font-weight: $font-medium;
      font-size: $text-12;
      width: 100%;
      height: rem(40px);
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      line-height: 1;
      padding: 16px;
      margin: 0 5px;
      &.active {
        background-image: url("../../../images/game-blue.png");
        color: white;
      }
      &:hover {
        box-shadow: $slate-gray-hover-shadow;
      }
      span {
        width: 40%;
        padding-right: 5px;
        .icon-grey {
          margin: auto;
          display: block;
        }
        .icon-white {
          margin: auto;
          display: none;
        }
      }
      p {
        width: 60%;
        line-height: 1.2;
      }
    }
  }
}

.cert-img {
  object-fit: contain;
  position: absolute;
  top: 14%;
  left: 4%;
  img {
    width: 100%;
    height: auto;
  }
}
.cert-content-container {
  position: absolute;
  top: 14%;
  right: 4%;
  width: 46%;

  h1 {
    color: $blue-500;
    font-weight: $font-semibold;
    text-shadow: 0 3px 6px rgba($blue-400, 0.4);
  }

  p {
    height: 360px;
    overflow: auto;
    padding-right: 1rem;
  }
}

//certificate mobile
.certificate-m-section {
  background-image: url("../../../images/certificate/page-bg.webp");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  .cert-img {
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    img {
      min-width: 20rem;
    }
  }
  .cert-content-container {
    width: 20rem;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    p {
      height: rem(240px);
    }
  }
}
